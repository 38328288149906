import axios from 'axios'

const baseURL = `https://api-iswa.ngo-stats.com/api`
// const baseURL = `http://localhost:3500/api`
const user = JSON.parse(window.localStorage.getItem('user'))

const instance = axios.create({
  baseURL,
  headers: {
    authorization: user?.authToken
  }
})

// Add a response interceptor
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 403) {
      window.localStorage.removeItem('user')
      window.location.reload()
    }
    return Promise.reject(error)
  }
)

export default instance
