import {
  Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow
} from '@mui/material'
import { deleteOrganization, getOrganizations, downloadExcel } from 'api/organizations'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { hideLoader, showLoader } from 'redux/slices/loader'
import { useTranslation } from 'react-i18next'
import Swal from '../swal'

export default function OrganizationsTable({ handleEdit, handleError, refresh }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [organizations, setOrganizations] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1)
  const [totalOrganizations, setTotalOrganizations] = useState(0)
  const [search, setSearch] = useState('')
  const [sortAttribute, setSortAttribute] = useState('id')
  const [sortDirection, setSortDirection] = useState('DESC')

  const loadData = useCallback(async () => {
    dispatch(showLoader())
    try {
      const query = {
        limit: pageSize,
        offset: (page - 1) * pageSize,
        sort_by_direction: sortDirection,
        sort_by_attribute: sortAttribute
      }
      if (search.length > 0) {
        query.search = search
      }
      const response = await getOrganizations(query)
      setOrganizations(response.data.data.rows)
      setTotalOrganizations(response.data.data.count)
    } catch (err) {
      console.log(err)
    }
    dispatch(hideLoader())
  }, [page, pageSize, sortDirection, sortAttribute, search, dispatch])
  // change according to page number

  useEffect(() => {
    refresh.current = loadData
  }, [loadData, refresh])

  useEffect(() => {
    loadData()
  }, [page, pageSize])

  // * download excel
  const exportData = useCallback(async () => {
    dispatch(showLoader())
    try {
      const query = {
        sort_by_direction: sortDirection,
        sort_by_attribute: sortAttribute
      }
      if (search.length > 0) {
        query.search = search
      }
      downloadExcel(query)
        .then((response) => {
          // create file link in browser's memory // ! remove replace
          const href = `${window.location.origin}/${response.data.data.fileName}`
          // create "a" HTML element with href to file & click
          const link = document.createElement('a')
          link.href = href
          link.setAttribute('download', response.data.data.fileName.split('/')[1]) // or any other extension
          document.body.appendChild(link)
          link.click()

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link)
          URL.revokeObjectURL(href)
        })
    } catch (err) {
      console.log(err)
    }
    dispatch(hideLoader())
  }, [page, pageSize, sortDirection, sortAttribute, search, dispatch]) // change according to page number

  const handleDelete = async (c) => {
    const { isConfirmed } = await Swal.fire({
      title: t('Are you sure?'),
      text: t("You won't be able to revert this!"),
      icon: t('warning'),
      showCancelButton: true,
      confirmButtonText: t('Yes, delete it!')
    })
    if (isConfirmed) {
      dispatch(showLoader())
      try {
        await deleteOrganization({
          id: c.id
        })
        dispatch(hideLoader())
        Swal.fire({
          icon: t('success'),
          title: t('Deleted!'),
          text: t('Organization deleted successfully'),
          showConfirmButton: false,
          timer: 1000
        })
      } catch (err) {
        dispatch(hideLoader())
        const error = err.response.data
        if (error.validationErrors) {
          handleError(error.validationErrors[0].msg)
        } else {
          handleError(t('Something went wrong! Please refresh the page.'))
        }
      }
      loadData()
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-md-3">
          <div className="form-group">
            <label>{t('Search')}</label>
            <input
              type="text"
              className="form-control"
              placeholder={t('Name') + t('or') + t('phone')}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>{t('Sort By')}</label>
            <select
              className="custom-select"
              value={sortAttribute}
              onChange={(e) => setSortAttribute(e.target.value)}
            >
              <option value="id">{t('ID')}</option>
              <option value="name">{t('Name')}</option>
            </select>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>{t('Direction')}</label>
            <select
              className="custom-select"
              value={sortDirection}
              onChange={(e) => setSortDirection(e.target.value)}
            >
              <option value="ASC">{t('Ascending')}</option>
              <option value="DESC">{t('Descending')}</option>
            </select>
          </div>
        </div>
        <div className="col-md-3 text-right d-flex align-items-end justify-content-between mb-3">
          <button
            onClick={loadData}
            type="button"
            className="btn bg-info"
          >
            <i className="fas fa-search mr-2" />
            {t('Search')}
          </button>
          <button
            onClick={exportData}
            type="button"
            className="btn bg-primary"
          >
            <i className="fas fa-file-download" />
          </button>
        </div>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('ID')}</TableCell>
            <TableCell>{t('Name')}</TableCell>
            <TableCell>{t('Created At')}</TableCell>
            <TableCell width="20%">{t('Action')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {organizations?.map((e) => (
            <TableRow
              hover
              key={e?.id}
            >
              <TableCell>{e?.id}</TableCell>
              <TableCell>{e?.name}</TableCell>
              <TableCell>{(e?.createdAt && moment(e?.createdAt).format('DD/MM/YYYY'))}</TableCell>
              <TableCell>
                <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => handleEdit(e)}>
                  <i className="fas fa-edit fa-fw" />
                </button>
                <button type="button" className="btn btn-sm btn-danger" onClick={() => handleDelete(e)}>
                  <i className="fas fa-trash fa-fw" />
                </button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={totalOrganizations || 0}
              page={page - 1}
              onPageChange={(e, v) => setPage(v + 1)}
              rowsPerPageOptions={[10, 25, 50]}
              rowsPerPage={pageSize}
              onRowsPerPageChange={(e) => setPageSize(e.target.value)}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </>
  )
}
