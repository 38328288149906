import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { showLoader, hideLoader } from 'redux/slices/loader'
import compressImage from 'lib/imageCompress'
import { addMedicalCase } from 'api/medicalCases'
import imagePlaceholder from 'assets/image-placeholder.png'
import { Toast } from 'components'
import { useTranslation } from 'react-i18next'

export default function CreateMedicalCase() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [toastOpen, setToastOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [donationType, setDonationType] = useState('')
  const [medicalReportPicture, setMedicalReportPicture] = useState(null)
  const donationTypes = ['Hospital', 'Surgery', 'Medicine']
  const maritalStatuses = ['Single', 'Married', 'Divorced', 'Widowed']
  const medicalCaseFormData = {
    full_name: null,
    birth_date: null,
    marital_status: null,
    residency_location: null,
    nationality: null,
    phone_number: null,
    land_line: null,
    fees: null,
    fees_currency: null,
    followup_person: null,
    notes: null,
    doners: null,
    donation: null
  }
  const medicalCaseFormValidation = useFormik({
    enableReinitialize: true,
    initialValues: medicalCaseFormData,
    validationSchema: yup.object().shape({
      full_name: yup.string(t('Must provide Full Name')).trim().required(),
    }),
    onSubmit: async (values) => {
      dispatch(showLoader())
      const data = { ...values }
      if (data.donation === "other") {
        data.donation = donationType
      }
      if (medicalReportPicture) {
        data.medical_report_picture = medicalReportPicture
      }
      if (data.doners?.length > 0) {
        const doners = []
        data.doners.split('\n').forEach(e => {
          const s = e.trim()
          if (s !== "") {
            doners.push(s)
          }
        })
        data.doners = doners
      } else {
        data.doners = []
      }
      addMedicalCase(data)
        .then((response) => {
          dispatch(hideLoader())
          if (response.status === 200) {
            navigate('/medical-cases')
          }
        }).catch(response => {
          setErrorMessage('Internal Server Error!')
          dispatch(hideLoader())
        })
    }
  })

  useEffect(() => {
    if (errorMessage !== '') {
      setToastOpen(true)
    }
  }, [errorMessage])

  useEffect(() => {
    if (toastOpen === false) {
      setErrorMessage('')
    }
  }, [toastOpen])

  return (
    <>
      <Toast open={toastOpen} onClose={() => setToastOpen(false)} message={errorMessage} type="error" />
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{t('Add Medical Case')}</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <form
                  id="form"
                  encType="multipart/form-data"
                  onSubmit={medicalCaseFormValidation.handleSubmit}
                >
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label htmlFor="full_name">
                        {t('Full Name')}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        id="full_name"
                        name="full_name"
                        type="text"
                        required=""
                        onChange={medicalCaseFormValidation.handleChange}
                        value={medicalCaseFormValidation.values?.full_name}
                        className={`form-control ${medicalCaseFormValidation.touched.full_name && medicalCaseFormValidation.errors.full_name && 'is-invalid'}`}
                      />
                      {medicalCaseFormValidation.touched.full_name && medicalCaseFormValidation.errors.full_name && (<span className="error invalid-feedback">{medicalCaseFormValidation.errors.full_name}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="birth_date">{t('Date of birth')}</label>
                      <input
                        type="date"
                        id="birth_date"
                        name="birth_date"
                        autoComplete="off"
                        data-date="23 May 2022"
                        data-date-format="DD MMMM YYYY"
                        onChange={medicalCaseFormValidation.handleChange}
                        value={medicalCaseFormValidation.values?.birth_date}
                        className={`form-control ${medicalCaseFormValidation.touched.birth_date && medicalCaseFormValidation.errors.birth_date && 'is-invalid'}`}
                      />
                      {medicalCaseFormValidation.touched.birth_date && medicalCaseFormValidation.errors.birth_date && (<span className="error invalid-feedback">{medicalCaseFormValidation.errors.birth_date}</span>)}
                      <span className="focus-border" />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="marital_status">
                        {t('Marital Status')}
                      </label>
                      <select
                        value={medicalCaseFormValidation.values?.marital_status}
                        name="marital_status"
                        onChange={medicalCaseFormValidation.handleChange}
                        className={`custom-select ${medicalCaseFormValidation.touched.marital_status && medicalCaseFormValidation.errors.marital_status && 'is-invalid'}`}
                      >
                        <option value="" selected disabled>{t('Choose') + t('Marital Status')}</option>
                        {maritalStatuses.map(e => (<option key={e} value={t(e, { lng: 'ar' })}>{t(e)}</option>))}
                      </select>
                      {medicalCaseFormValidation.touched.marital_status && medicalCaseFormValidation.errors.marital_status && (<span className="error invalid-feedback">{medicalCaseFormValidation.errors.marital_status}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="residency_location">{t('Location of residence')}</label>
                      <input
                        id="residency_location"
                        name="residency_location"
                        type="text"
                        onChange={medicalCaseFormValidation.handleChange}
                        value={medicalCaseFormValidation.values?.residency_location}
                        className={`form-control ${medicalCaseFormValidation.touched.residency_location && medicalCaseFormValidation.errors.residency_location && 'is-invalid'}`}
                      />
                      {medicalCaseFormValidation.touched.residency_location && medicalCaseFormValidation.errors.residency_location && (<span className="error invalid-feedback">{medicalCaseFormValidation.errors.residency_location}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="nationality">
                        {t('Nationality')}
                      </label>
                      <input
                        id="nationality"
                        type="text"
                        name="nationality"
                        required=""
                        onChange={medicalCaseFormValidation.handleChange}
                        value={medicalCaseFormValidation.values?.nationality}
                        className={`form-control ${medicalCaseFormValidation.touched.nationality && medicalCaseFormValidation.errors.nationality && 'is-invalid'}`}
                      />
                      {medicalCaseFormValidation.touched.nationality && medicalCaseFormValidation.errors.nationality && (<span className="error invalid-feedback">{medicalCaseFormValidation.errors.nationality}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="phone_number">
                        {t('Phone')}
                      </label>
                      <input
                        id="phone_number"
                        type="text"
                        name="phone_number"
                        required=""
                        onChange={medicalCaseFormValidation.handleChange}
                        value={medicalCaseFormValidation.values?.phone_number}
                        className={`form-control ${medicalCaseFormValidation.touched.phone_number && medicalCaseFormValidation.errors.phone_number && 'is-invalid'}`}
                      />
                      {medicalCaseFormValidation.touched.phone_number && medicalCaseFormValidation.errors.phone_number && (<span className="error invalid-feedback">{medicalCaseFormValidation.errors.phone_number}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="land_line">
                        {t('Land Line')}
                      </label>
                      <input
                        id="land_line"
                        type="text"
                        name="land_line"
                        onChange={medicalCaseFormValidation.handleChange}
                        value={medicalCaseFormValidation.values?.land_line}
                        className={`form-control ${medicalCaseFormValidation.touched.land_line && medicalCaseFormValidation.errors.land_line && 'is-invalid'}`}
                      />
                      {medicalCaseFormValidation.touched.land_line && medicalCaseFormValidation.errors.land_line && (<span className="error invalid-feedback">{medicalCaseFormValidation.errors.land_line}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="followup_person">
                        {t('Follow Up Person')}
                      </label>
                      <input
                        id="followup_person"
                        type="text"
                        name="followup_person"
                        onChange={medicalCaseFormValidation.handleChange}
                        value={medicalCaseFormValidation.values?.followup_person}
                        className={`form-control ${medicalCaseFormValidation.touched.followup_person && medicalCaseFormValidation.errors.followup_person && 'is-invalid'}`}
                      />
                      {medicalCaseFormValidation.touched.followup_person && medicalCaseFormValidation.errors.followup_person && (<span className="error invalid-feedback">{medicalCaseFormValidation.errors.followup_person}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="donation">{t('Donation Type')}</label>
                      <select
                        name="donation"
                        value={medicalCaseFormValidation.values?.donation}
                        onChange={medicalCaseFormValidation.handleChange}
                        className={`custom-select ${medicalCaseFormValidation.touched.donation && medicalCaseFormValidation.errors.donation && 'is-invalid'}`}
                      >
                        <option value="" selected disabled>{t('Choose')}</option>
                        {donationTypes.map(e => (<option key={e} value={t(e, { lng: 'ar' })}>{t(e)}</option>))}
                        <option value="other">{t('Add Type')}</option>
                      </select>
                      {(medicalCaseFormValidation.values?.donation === "other")
                        && (
                          <input
                            type="text"
                            onChange={(e) => setDonationType(e.target.value)}
                            value={donationType}
                            className="form-control mt-3"
                            placeholder={t('Donation Type')}
                          />
                        )}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="fees">
                        {t('Fees')}
                      </label>
                      <input
                        id="fees"
                        type="number"
                        name="fees"
                        onChange={medicalCaseFormValidation.handleChange}
                        value={medicalCaseFormValidation.values?.fees}
                        className={`form-control ${medicalCaseFormValidation.touched.fees && medicalCaseFormValidation.errors.fees && 'is-invalid'}`}
                      />
                      {medicalCaseFormValidation.touched.fees && medicalCaseFormValidation.errors.fees && (<span className="error invalid-feedback">{medicalCaseFormValidation.errors.fees}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="fees">
                        {t('Fees Currency')}
                      </label>
                      <select
                        value={medicalCaseFormValidation.values?.fees_currency}
                        name="fees_currency"
                        onChange={medicalCaseFormValidation.handleChange}
                        className={`custom-select ${medicalCaseFormValidation.touched.fees_currency && medicalCaseFormValidation.errors.fees_currency && 'is-invalid'}`}
                      >
                        <option value="" selected disabled>{t('Choose Currency')}</option>
                        {['LBP', 'USD'].map(e => (<option key={e} value={t(e, { lng: 'ar' })}>{t(e)}</option>))}
                      </select>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="doners">{t('Doners (each doner on a separate line)')}</label>
                      <textarea
                        cols="0"
                        rows="6"
                        name="doners"
                        id="doners"
                        onChange={medicalCaseFormValidation.handleChange}
                        value={medicalCaseFormValidation.values?.doners}
                        className={`form-control ${medicalCaseFormValidation.touched.doners && medicalCaseFormValidation.errors.doners && 'is-invalid'}`}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="notes">{t('Notes')}</label>
                      <textarea
                        cols="0"
                        rows="6"
                        name="notes"
                        id="notes"
                        onChange={medicalCaseFormValidation.handleChange}
                        value={medicalCaseFormValidation.values?.notes}
                        className={`form-control ${medicalCaseFormValidation.touched.notes && medicalCaseFormValidation.errors.notes && 'is-invalid'}`}
                      />
                      {medicalCaseFormValidation.touched.notes && medicalCaseFormValidation.errors.notes && (<span className="error invalid-feedback">{medicalCaseFormValidation.errors.notes}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="medical_report">{t('Picture of Medical Report')}</label>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="medical_report"
                          name="medical_report"
                          onChange={compressImage(setMedicalReportPicture)}
                        />
                        <span className="file-input-name-preview col-md-3" />
                        <label className="custom-file-label" htmlFor="customFile" />
                      </div>
                    </div>
                    <div className="form-group col-md-3">
                      <img
                        src={medicalReportPicture || imagePlaceholder}
                        alt=""
                        className="img-thumbnail bg-light"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col d-flex justify-content-center">
                      <button type="submit" className="btn btn-primary">{t('Save')}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
