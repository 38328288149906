import { createSlice } from '@reduxjs/toolkit'

export const roleSlice = createSlice({
  name: 'role',
  initialState: {
    role: localStorage.getItem('role'),
  },
  reducers: {
    setRole: (state, action) => {
      state.role = action.payload
    },
    resetRole: (state) => {
      state.role = ''
    }
  },
})

// Action creators are generated for each case reducer function
export const { setRole, resetRole } = roleSlice.actions

export default roleSlice.reducer
