import { addOrganization, updateOrganization } from 'api/organizations'
import {
  ContentHeader, OrganizationsTable, CustomModal, Toast
} from 'components'
import React, { useEffect, useState, useRef } from 'react'
import { showLoader, hideLoader } from 'redux/slices/loader'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';

export default function Organizations() {
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const [name, setName] = useState('')
  const [shouldValidate, setShouldValidate] = useState(false)
  const [toastOpen, setToastOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [modalUpdating, setModalUpdating] = useState(false)
  const [selectedOrganization, setSelectedOrganization] = useState()
  const openModal = () => setModalOpen(true)
  const refreshTable = useRef(null)

  const resetForm = () => {
    setName('')
    setModalUpdating(false)
  }

  const closeModal = () => {
    resetForm()
    setModalOpen(false)
  }

  const editOrganizationModal = (c) => {
    setName(c.name)
    setSelectedOrganization(c.id)
    setModalUpdating(true)
    openModal()
  }

  useEffect(() => {
    if (errorMessage !== '') {
      setToastOpen(true)
    }
  }, [errorMessage])

  useEffect(() => {
    if (toastOpen === false) {
      setErrorMessage('')
    }
  }, [toastOpen])

  useEffect(() => {
    setShouldValidate(false)
  }, [modalOpen])

  const handleForm = async () => {
    if (name === '') {
      setShouldValidate(true)
    } else {
      dispatch(showLoader())
      try {
        const data = {
          name
        }
        if (modalUpdating) {
          await updateOrganization(data, selectedOrganization)
        } else {
          await addOrganization(data)
        }
        setModalOpen(false)
        resetForm()
        refreshTable.current()
      } catch (err) {
        const error = err?.response?.data
        if (error?.validationErrors) {
          setErrorMessage(error.validationErrors[0].msg)
        } else if (err?.response?.status === 409) {
          setErrorMessage(error.message)
        } else {
          setErrorMessage(t('Something went wrong! Please refresh the page.'))
        }
      }
      dispatch(hideLoader())
    }
  }

  const handleInputChange = (setter) => (e) => setter(e.target.value)

  return (
    <>
      <Toast open={toastOpen} onClose={() => setToastOpen(false)} message={errorMessage} type="error" />
      <ContentHeader title={t('Organizations')} hasAdd handleModal={openModal} />
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <OrganizationsTable
                    handleEdit={editOrganizationModal}
                    handleError={setErrorMessage}
                    refresh={refreshTable}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CustomModal
        handleClose={closeModal}
        handleForm={handleForm}
        headerText={modalUpdating ? t('Edit Organization') : t('Add Organization')}
        submitText={modalUpdating ? t('Update') : t('Add')}
        open={modalOpen}
      >
        <form className="needs-validation">
          <div className="form-group">
            <label htmlFor="name">
              {t('Name')}
              <span className="text-danger">*</span>
            </label>
            <input
              id="name"
              value={name}
              onChange={handleInputChange(setName)}
              autoComplete="off"
              type="text"
              className={`form-control ${shouldValidate && name === '' && 'is-invalid'}`}
            />
            {shouldValidate && name === '' && (<span className="error invalid-feedback">{t('Invalid name')}</span>)}
          </div>
        </form>
      </CustomModal>
    </>
  )
}
