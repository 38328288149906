import imageCompression from 'browser-image-compression'

const compress = (imageSetter) => async (e) => {
  if (e.target.files.length === 0) {
    return null
  }
  try {
    const image = await imageCompression(e.target.files[0], {
      maxSizeMB: 0.25,
      maxWidthOrHeight: 1920,
    })
    const dataUrl = await imageCompression.getDataUrlFromFile(image)
    imageSetter(dataUrl)
  } catch (err) {
    console.log(err)
  }
}

export default compress
