import axios from '../lib/axios'

export const addMedicalEquipment = async (payload) => {
  const res = await axios.post('/medical-equipment', payload)
  return res
}

export const getAllMedicalEquipment = async (params) => {
  const res = await axios.get('/medical-equipment', {
    params
  })

  return res
}

export const deleteMedicalEquipment = async ({ id }) => {
  const res = await axios.delete(`/medical-equipment/${id}`)

  return res
}

export const getMedicalEquipment = async (id) => {
  const res = await axios.get(`/medical-equipment/${id}`)

  return res
}

export const updateMedicalEquipment = async (id, payload) => {
  const res = await axios.patch(`/medical-equipment/${id}`, payload)

  return res
}

export const downloadExcel = async (params) => {
  const res = await axios.get('/medical-equipment/downloadExcel', {
    params
  })
  return res
}
