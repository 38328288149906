import React from 'react'
import Loader from '../Loader'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar'

export default function Wrapper({ children }) {
  return (
    <div className="wrapper">
      <Loader />
      <Navbar />
      <Sidebar />
      <div className="content-wrapper">
        {children}
      </div>
    </div>
  )
}
