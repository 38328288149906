import axios from '../lib/axios'

export const getFamily = async (id) => {
  const res = await axios.get(`/family/${id}`)
  return res
}

export const getFamilies = async (params) => {
  const res = await axios.get('/family', {
    params
  })
  return res
}

export const addFamily = async (payload) => {
  const res = await axios.post('/family', payload)
  return res
}

export const updateFamily = async (id, payload) => {
  const res = await axios.patch(`/family/${id}`, payload)
  return res
}

export const deleteFamily = async ({ id }) => {
  const res = await axios.delete(`/family/${id}`)
  return res
}

export const downloadExcel = async (params) => {
  const res = await axios.get('/family/downloadExcel', {
    params
  })
  return res
}
