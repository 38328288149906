/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showLoader, hideLoader } from 'redux/slices/loader'
import { ContentHeader, Toast } from 'components'
import BarChart from 'components/charts/barChart'
import { getStats } from 'api/dashboard'

export default function Dashboard() {
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const [toastOpen, setToastOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [chart1, setChart1] = useState([])
  const [chart2, setChart2] = useState([])
  const [chart3, setChart3] = useState([])
  const [chart4, setChart4] = useState([])

  useEffect(() => {
    document.title = t('Dashboard');
    dispatch(showLoader())
    getStats()
      .then((response) => {
        const res = response.data.data
        setChart1(res.chart_1.map(e => ({
          name: t(e.name),
          value: e.value
        })))
        setChart2(res.chart_2.map(e => ({
          name: t(e.name),
          value: e.value
        })))
        setChart3(res.chart_3)
        setChart4(res.chart_4)
        dispatch(hideLoader())
      })
      .catch((response) => {
        setErrorMessage('Internal Server Error!')
        dispatch(hideLoader())
      })
  }, [])

  useEffect(() => {
    if (errorMessage !== '') {
      setToastOpen(true)
    }
  }, [errorMessage])

  useEffect(() => {
    if (toastOpen === false) {
      setErrorMessage('')
    }
  }, [toastOpen])

  return (
    <>
      <Toast open={toastOpen} onClose={() => setToastOpen(false)} message={errorMessage} type="error" />
      <ContentHeader title={t("Dashboard")} />
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <BarChart data={chart1} title="Total Forms" dataKey="value" />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <BarChart data={chart2} title="Total Doners" dataKey="value" />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <BarChart data={chart3} title="Medical Cases Doners" />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <BarChart data={chart4} title="Families Doners" />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
