import axios from '../lib/axios'

export const getEmergency = async (id) => {
  const res = await axios.get(`/emergency/${id}`)
  return res
}

export const getEmergencies = async (params) => {
  const res = await axios.get('/emergency', {
    params
  })
  return res
}

export const addEmergency = async (payload) => {
  const res = await axios.post('/emergency', payload)
  return res
}

export const updateEmergency = async (id, payload) => {
  const res = await axios.patch(`/emergency/${id}`, payload)
  return res
}

export const deleteEmergency = async ({ id }) => {
  const res = await axios.delete(`/emergency/${id}`)
  return res
}
