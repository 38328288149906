import { useTranslation } from 'react-i18next'
import React from 'react'

export default function ContentHeader({ title, hasAdd, handleModal }) {
  const { t } = useTranslation()
  return (
    <section className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1>{title}</h1>
          </div>
          {hasAdd
            ? (
              <div className="col-sm-6 text-right">
                <button
                  onClick={handleModal}
                  type="button"
                  id="addButton"
                  className="btn bg-info"
                  data-toggle="modal"
                  data-target="#addModal"
                >
                  <i className="fas fa-plus mr-2" />
                  {t('Add')}
                </button>
              </div>
            ) : null}
        </div>
      </div>
    </section>
  )
}
