import {
  ContentHeader, EmergenciesTable, Toast
} from 'components'
import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next';

export default function Emergencies() {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const [toastOpen, setToastOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const openModal = () => navigate('/emergencies/create')
  const refreshTable = useRef(null)

  const editEmergencyHandler = (e) => {
    navigate(`/emergencies/edit/${e.id}`)
  }

  return (
    <>
      <Toast open={toastOpen} onClose={() => setToastOpen(false)} message={errorMessage} type="error" />
      <ContentHeader title={t('Emergency')} hasAdd handleModal={openModal} />
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <EmergenciesTable
                    handleEdit={editEmergencyHandler}
                    handleError={setErrorMessage}
                    refresh={refreshTable}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
