import { login } from 'api/auth'
import React, { useState } from 'react'
import {
  FaEnvelope,
  FaLock,
} from 'react-icons/fa'
import { setRole } from 'redux/slices/role'
import { useTranslation } from 'react-i18next';
import { showLoader, hideLoader } from 'redux/slices/loader'
import { useDispatch } from 'react-redux'

export default function LoginScreen() {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [shouldValidate, setShouldValidate] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const handleChange = setter => event => {
    setErrorMessage('')
    setter(event.target.value)
  }

  const onLogin = async (e) => {
    e.preventDefault()
    if (username === '' || password.length < 8) {
      setShouldValidate(true)
      setErrorMessage(`${t('Invalid Values')}`)
    } else {
      try {
        dispatch(showLoader())
        const response = await login({
          username,
          password,
        })
        dispatch(hideLoader())
        dispatch(setRole(response.data.data.user.role))
        localStorage.setItem('user', JSON.stringify(response.data.data.user))
        localStorage.setItem('role', response.data.data.user.role)
        window.location.reload()
      } catch (err) {
        dispatch(hideLoader())
        setPassword('')
        if (err.response?.status === 400) {
          setErrorMessage(err.response.data.message)
        } else {
          setErrorMessage(`${t('Something went wrong, please try again later')}`)
        }
      }
    }
  }

  return (
    <div className="vh-100 d-flex justify-content-center align-items-center">
      <div className="login-box">
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <img src="/dist/img/logo.png" alt="logo" className="w-50" />
            <br />
            <a href="/" className="h1">{t('Dashboard')}</a>
          </div>
          <div className="card-body">
            <p className="login-box-msg">
              {t('sign in')}
            </p>
            <form action="/auth/" method="post">
              <div className="input-group mb-3">
                <input type="username" className={`form-control ${shouldValidate && username === '' ? 'is-invalid' : ''}`} placeholder="Username" onChange={handleChange(setUsername)} />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <FaEnvelope />
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input type="password" className={`form-control ${shouldValidate && username === '' ? 'is-invalid' : ''}`} placeholder="Password" onChange={handleChange(setPassword)} />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <FaLock />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <span className="text-danger">
                    &nbsp;
                    {errorMessage}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <button type="submit" className="btn btn-primary btn-block" onClick={onLogin}>{t('sign in')}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
