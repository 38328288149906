import React from 'react'
import { FaBars } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

export default function Navbar() {
  const { t, i18n } = useTranslation()
  const onLogout = () => {
    localStorage.removeItem('user')
    window.location.reload()
  }

  const changeLanguage = (e) => {
    i18n.changeLanguage(e.target.value).then(() => {
      localStorage.setItem('lang', e.target.value)
      const style = document.getElementById('style-direction')
      if (e.target.value === 'ar') {
        style.href = '/dist/css/adminlte.rtl.css'
        document.documentElement.dir = 'rtl'
      } else {
        style.href = '/dist/css/adminlte.min.css'
        document.documentElement.dir = 'ltr'
      }
      location.reload()
    })
  }
  // To open a model showing the message and its content
  const dummyMessages = [
    {
      to: '',
      read: false,
      title: 'Renting a wheel chair',
      date: '12/26/2022'
    },
    {
      to: '',
      read: true,
      title: 'Renting a walker',
      date: '12/12/2022'
    },
    {
      to: '',
      read: false,
      title: 'Renting a hospital bed',
      date: '12/1/2022'
    },
  ]
  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="/" role="button"><FaBars /></a>
        </li>
      </ul>
      <ul className="navbar-nav ml-auto">
        {/* <NotificationDropdown /> */}
        {/* <li className="nav-item dropdown">
          <a className="nav-link" data-toggle="dropdown" href="#">
            <i className="far fa-bell" />
            <span className="badge badge-warning navbar-badge">{dummyMessages.length}</span>
          </a>
          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <span className="dropdown-item dropdown-header">
              {`${dummyMessages.length} Notifications`}
            </span>
            {
              dummyMessages.map(message => (
                <>
                  <div className="dropdown-divider" />
                  <a href="#" className="dropdown-item">
                    { message.read ? <i className="fa-solid fas fa-envelope-open mr-2" /> : <i className="fas fa-envelope mr-2" />}
                    {message.title}
                    <span className="float-right text-muted text-sm">{message.date}</span>
                  </a>
                  <div className="dropdown-divider" />
                </>
              ))
            }
            <a href="#" className="dropdown-item dropdown-footer">See All Notifications</a>
          </div>
        </li> */}
        <li className="nav-item">
          <select
            className="custom-select"
            onChange={changeLanguage}
            value={i18n.language}
          >
            <option value="en">{t('English')}</option>
            <option value="ar">{t('Arabic')}</option>
          </select>
        </li>
        <li className="nav-item">
          <a className="nav-link" data-widget="control-sidebar" data-controlsidebar-slide="true" href="/auth" role="button" onClick={onLogout}>
            <i className="fas fa-sign-out-alt" />
          </a>
        </li>
      </ul>
    </nav>
  )
}
