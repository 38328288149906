import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import englishTranslations from './translations/en.json'
import arabicTranslations from './translations/ar.json'

const resources = {
  en: {
    translation: englishTranslations
  },
  ar: {
    translation: arabicTranslations
  }
}

let lang = localStorage.getItem('lang')
if (!lang) { // if lang is not saved
  localStorage.setItem('lang', 'en')
  lang = 'en'
}
document.documentElement.lang = lang;
const style = document.getElementById('style-direction');
if (lang === 'ar') {
  style.href = '/dist/css/adminlte.rtl.css';
  document.documentElement.dir = 'rtl';
} else {
  style.href = '/dist/css/adminlte.min.css';
  document.documentElement.dir = 'ltr';
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: lang,

    interpolation: {
      escapeValue: false
    }
  })

export default i18n
