import * as React from 'react'
import { Alert, Snackbar, Slide } from '@mui/material'

function Transition(props) {
  return <Slide {...props} direction="down" />
}

export default function Toast({
  open, onClose, message, type
}) {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    onClose()
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      TransitionComponent={Transition}
    >
      <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  )
}
