import axios from '../lib/axios'

export const getMedicalCase = async (id) => {
  const res = await axios.get(`/medical-cases/${id}`)

  return res
}

export const getMedicalCases = async (params) => {
  const res = await axios.get('/medical-cases', {
    params
  })

  return res
}

export const addMedicalCase = async (payload) => {
  const res = await axios.post('/medical-cases', payload)

  return res
}

export const deleteMedicalCase = async (id) => {
  const res = await axios.delete(`/medical-cases/${id}`)

  return res
}

export const updateMedicalCase = async (id, payload) => {
  const res = await axios.patch(`/medical-cases/${id}`, payload)

  return res
}

export const downloadExcel = async (params) => {
  const res = await axios.get('/medical-cases/downloadExcel', {
    params
  })
  return res
}
