import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { showLoader, hideLoader } from 'redux/slices/loader'
import compressImage from 'lib/imageCompress'
import { addFamily } from 'api/families'
import imagePlaceholder from 'assets/image-placeholder.png'
import { Toast } from 'components'
import { useTranslation } from 'react-i18next'

export default function CreateFamily() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const genders = ['Male', 'Female']
  const incomeLevels = ['Good', 'Average', 'Bad', 'Very Bad']
  const maritalStatuses = ['Single', 'Married', 'Divorced', 'Widowed']
  const donations = ['Fuel', 'Food Parcel', 'Clothing', 'Cash Money', 'Winter Kit']
  const booleanOptions = [{
    name: t('No'),
    value: false
  },
  {
    name: t('Yes'),
    value: true
  }]
  const navigate = useNavigate()
  const [toastOpen, setToastOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [donationType, setDonationType] = useState('')
  const [idPicture, setIdPicture] = useState(null)
  const [familyMembers, setFamilyMembers] = useState([])
  const [familyMembersInputErrors, setFamilyMembersInputErrors] = useState([])
  const familyFormData = {
    full_name: null,
    mother_name: null,
    application_date: null,
    birth_date: null,
    nationality: null,
    record_number: null,
    breadwinner: null,
    record_place: null,
    gender: null,
    birth_place: null,
    phone_number: null,
    passport_number: null,
    id_number: null,
    marital_status: null,
    work: null,
    work_place: null,
    region: null,
    street: null,
    building: null,
    beside: null,
    floor: null,
    house_owner_name: null,
    housing_status: null,
    rent_value: null,
    rent_value_currency: '',
    number_of_rooms: null,
    number_of_children: null,
    medical_status: null,
    sickness_type: null,
    disability_type: null,
    social_security: 0,
    health_insurance: null,
    notes: null,
    donation: '',
    doners: null,
    partner_is_dead: 0,
    partner_full_name: null,
    partner_mother_name: null,
    partner_birth_date: null,
    partner_nationality: null,
    partner_record_number: null,
    partner_record_place: null,
    partner_gender: null,
    partner_work: null,
    partner_medical_status: null,
    partner_sickness_type: null,
    partner_disability_type: null,
    partner_social_security: 0,
    partner_health_insurance: null,
    partner_social_status: null,
    partner_phone_number: null,
    unesco: 0,
  }
  const familyFormValidation = useFormik({
    enableReinitialize: true,
    initialValues: familyFormData,
    validationSchema: yup.object().shape({
      full_name: yup.string(t('Must provide Full Name')).trim().required(),
    }),
    onSubmit: (values) => {
      dispatch(showLoader())
      const data = { ...values }
      if (data.donation === "other") {
        data.donation = donationType
      }
      if (idPicture) {
        data.id_picture = idPicture
      }
      data.family_members = familyMembers
      if (data.doners?.length > 0) {
        const doners = []
        data.doners.split('\n').forEach(e => {
          const s = e.trim()
          if (s !== "") {
            doners.push(s)
          }
        })
        data.doners = doners
      } else {
        data.doners = []
      }
      addFamily(data)
        .then((response) => {
          dispatch(hideLoader())
          if (response.status === 200) {
            navigate('/families')
          }
        }).catch(response => {
          setErrorMessage('Internal Server Error!')
          dispatch(hideLoader())
        })
    }
  })

  useEffect(() => {
    if (errorMessage !== null) {
      setToastOpen(true)
    }
  }, [errorMessage])

  useEffect(() => {
    if (toastOpen === false) {
      setErrorMessage(null)
    }
  }, [toastOpen])

  const addMember = () => {
    setFamilyMembers(old => [
      ...old,
      {
        name: null,
        birth_date: null,
        gender: null,
        social_status: null,
        education_level: null,
        education_institute: null,
        medical_status: null,
        workers: null
      }])
    setFamilyMembersInputErrors(old => [
      ...old,
      {
        name: false,
        birth_date: false,
        gender: false,
        social_status: false,
        education_level: false,
        education_institute: false,
        medical_status: false,
        workers: false
      }])
  }

  const removeMember = (index) => {
    let f = [...familyMembers]
    f.splice(index, 1)
    setFamilyMembers(f)
    f = [...familyMembersInputErrors]
    f.splice(index, 1)
    setFamilyMembersInputErrors(f)
  }

  const handleMemberInputChange = (index, key) => (e) => {
    const { value } = e.target
    setFamilyMembers(
      familyMembers.map((member, i) => (
        i === index ? { ...member, [key]: value } : member
      ))
    )
  }

  return (
    <>
      <Toast open={toastOpen} onClose={() => setToastOpen(false)} message={errorMessage} type="error" />
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{t('Add Family')}</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <form
                  id="form"
                  encType="multipart/form-data"
                  onSubmit={familyFormValidation.handleSubmit}
                >
                  <h4>{t('Personal Info')}</h4>
                  <div className="form-row border-bottom border-secondary mb-3">
                    <div className="form-group col-md-3">
                      <label htmlFor="full_name">
                        {t('Full Name')}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        id="full_name"
                        name="full_name"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.full_name}
                        className={`form-control ${familyFormValidation.touched.full_name && familyFormValidation.errors.full_name && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.full_name && familyFormValidation.errors.full_name && (<span className="error invalid-feedback">{familyFormValidation.errors.full_name}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="mother_name">{t('Mother\'s Name')}</label>
                      <input
                        id="mother_name"
                        name="mother_name"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.mother_name}
                        className={`form-control ${familyFormValidation.touched.mother_name && familyFormValidation.errors.mother_name && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.mother_name && familyFormValidation.errors.mother_name && (<span className="error invalid-feedback">{familyFormValidation.errors.mother_name}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="birth_date">{t('Date of birth')}</label>
                      <input
                        type="date"
                        id="birth_date"
                        name="birth_date"
                        autoComplete="off"
                        data-date="23 May 2022"
                        data-date-format="DD MMMM YYYY"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.birth_date}
                        className={`form-control ${familyFormValidation.touched.birth_date && familyFormValidation.errors.birth_date && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.birth_date && familyFormValidation.errors.birth_date && (<span className="error invalid-feedback">{familyFormValidation.errors.birth_date}</span>)}
                      <span className="focus-border" />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="application_date">{t('Application Date')}</label>
                      <input
                        type="date"
                        id="application_date"
                        name="application_date"
                        autoComplete="off"
                        data-date="23 May 2022"
                        data-date-format="DD MMMM YYYY"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.application_date}
                        className={`form-control ${familyFormValidation.touched.application_date && familyFormValidation.errors.application_date && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.application_date && familyFormValidation.errors.application_date && (<span className="error invalid-feedback">{familyFormValidation.errors.application_date}</span>)}
                      <span className="focus-border" />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="nationality">
                        {t('Nationality')}
                      </label>
                      <input
                        id="nationality"
                        name="nationality"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.nationality}
                        className={`form-control ${familyFormValidation.touched.nationality && familyFormValidation.errors.nationality && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.nationality && familyFormValidation.errors.nationality && (<span className="error invalid-feedback">{familyFormValidation.errors.nationality}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="middle_name">{t('Record Number')}</label>
                      <input
                        id="record_number"
                        name="record_number"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.record_number}
                        className={`form-control ${familyFormValidation.touched.record_number && familyFormValidation.errors.record_number && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.record_number && familyFormValidation.errors.record_number && (<span className="error invalid-feedback">{familyFormValidation.errors.record_number}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="record_place">{t('Record Place')}</label>
                      <input
                        id="record_place"
                        name="record_place"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.record_place}
                        className={`form-control ${familyFormValidation.touched.record_place && familyFormValidation.errors.record_place && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.record_place && familyFormValidation.errors.record_place && (<span className="error invalid-feedback">{familyFormValidation.errors.record_place}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="gender">{t('Gender')}</label>
                      <select
                        name="gender"
                        value={familyFormValidation.values?.gender}
                        onChange={familyFormValidation.handleChange}
                        className={`custom-select ${familyFormValidation.touched.gender && familyFormValidation.errors.gender && 'is-invalid'}`}
                      >
                        <option value="" selected disabled>{t('Choose Gender')}</option>
                        {genders.map(e => (<option key={e} value={t(e, { lng: 'ar' })}>{t(e)}</option>))}
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="record_place">{t('Birth Place')}</label>
                      <input
                        id="birth_place"
                        name="birth_place"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.birth_place}
                        className={`form-control ${familyFormValidation.touched.birth_place && familyFormValidation.errors.birth_place && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.birth_place && familyFormValidation.errors.birth_place && (<span className="error invalid-feedback">{familyFormValidation.errors.birth_place}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="phone_number">
                        {t('Phone')}
                      </label>
                      <input
                        id="phone_number"
                        type="text"
                        name="phone_number"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.phone_number}
                        className={`form-control ${familyFormValidation.touched.phone_number && familyFormValidation.errors.phone_number && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.phone_number && familyFormValidation.errors.phone_number && (<span className="error invalid-feedback">{familyFormValidation.errors.phone_number}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="passport_number">{t('Passport Number')}</label>
                      <input
                        id="passport_number"
                        name="passport_number"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.passport_number}
                        className={`form-control ${familyFormValidation.touched.passport_number && familyFormValidation.errors.passport_number && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.passport_number && familyFormValidation.errors.passport_number && (<span className="error invalid-feedback">{familyFormValidation.errors.passport_number}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="id_number">{t('ID Number')}</label>
                      <input
                        id="id_number"
                        name="id_number"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.id_number}
                        className={`form-control ${familyFormValidation.touched.id_number && familyFormValidation.errors.id_number && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.id_number && familyFormValidation.errors.id_number && (<span className="error invalid-feedback">{familyFormValidation.errors.id_number}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="marital_status">
                        {t('Marital Status')}
                      </label>
                      <select
                        value={familyFormValidation.values?.marital_status}
                        name="marital_status"
                        onChange={familyFormValidation.handleChange}
                        className={`custom-select ${familyFormValidation.touched.marital_status && familyFormValidation.errors.marital_status && 'is-invalid'}`}
                      >
                        <option value="" selected disabled>{t('Choose Marital Status')}</option>
                        {maritalStatuses.map(e => (<option key={e} value={t(e, { lng: 'ar' })}>{t(e)}</option>))}
                      </select>
                      {familyFormValidation.touched.marital_status && familyFormValidation.errors.marital_status && (<span className="error invalid-feedback">{familyFormValidation.errors.marital_status}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="breadwinner">
                        {t('Income Level')}
                      </label>
                      <select
                        value={familyFormValidation.values?.breadwinner}
                        name="breadwinner"
                        onChange={familyFormValidation.handleChange}
                        className={`custom-select ${familyFormValidation.touched.breadwinner && familyFormValidation.errors.breadwinner && 'is-invalid'}`}
                      >
                        <option value="" selected disabled>{t('Choose Level')}</option>
                        {incomeLevels.map(e => (<option key={e} value={t(e, { lng: 'ar' })}>{t(e)}</option>))}
                      </select>
                      {familyFormValidation.touched.breadwinner && familyFormValidation.errors.breadwinner && (<span className="error invalid-feedback">{familyFormValidation.errors.breadwinner}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="work">{t('Work')}</label>
                      <input
                        id="work"
                        name="work"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.work}
                        className={`form-control ${familyFormValidation.touched.work && familyFormValidation.errors.work && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.work && familyFormValidation.errors.work && (<span className="error invalid-feedback">{familyFormValidation.errors.work}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="work_place">{t('Work Place')}</label>
                      <input
                        id="work_place"
                        name="work_place"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.work_place}
                        className={`form-control ${familyFormValidation.touched.work_place && familyFormValidation.errors.work_place && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.work_place && familyFormValidation.errors.work_place && (<span className="error invalid-feedback">{familyFormValidation.errors.work_place}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="number_of_children">{t('Number of Children')}</label>
                      <input
                        id="number_of_children"
                        name="number_of_children"
                        type="number"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.number_of_children}
                        className={`form-control ${familyFormValidation.touched.number_of_children && familyFormValidation.errors.number_of_children && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.number_of_children && familyFormValidation.errors.number_of_children && (<span className="error invalid-feedback">{familyFormValidation.errors.number_of_children}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="medical_status">{t('Medical Status')}</label>
                      <input
                        id="medical_status"
                        name="medical_status"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.medical_status}
                        className={`form-control ${familyFormValidation.touched.medical_status && familyFormValidation.errors.medical_status && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.medical_status && familyFormValidation.errors.medical_status && (<span className="error invalid-feedback">{familyFormValidation.errors.medical_status}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="sickness_type">{t('Sickness Type')}</label>
                      <input
                        id="sickness_type"
                        name="sickness_type"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.sickness_type}
                        className={`form-control ${familyFormValidation.touched.sickness_type && familyFormValidation.errors.sickness_type && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.sickness_type && familyFormValidation.errors.sickness_type && (<span className="error invalid-feedback">{familyFormValidation.errors.sickness_type}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="disability_type">{t('Disability Type')}</label>
                      <input
                        id="disability_type"
                        name="disability_type"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.disability_type}
                        className={`form-control ${familyFormValidation.touched.disability_type && familyFormValidation.errors.disability_type && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.disability_type && familyFormValidation.errors.disability_type && (<span className="error invalid-feedback">{familyFormValidation.errors.disability_type}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="social_security">
                        {t('Social Security')}
                      </label>
                      <select
                        value={familyFormValidation.values?.social_security}
                        name="social_security"
                        onChange={familyFormValidation.handleChange}
                        className={`custom-select ${familyFormValidation.touched.social_security && familyFormValidation.errors.social_security && 'is-invalid'}`}
                      >
                        {booleanOptions.map(e => (<option key={e.name} value={e.value}>{t(e.name)}</option>))}
                      </select>
                      {familyFormValidation.touched.social_security && familyFormValidation.errors.social_security && (<span className="error invalid-feedback">{familyFormValidation.errors.social_security}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="unesco">
                        {t('UNRWA')}
                      </label>
                      <select
                        value={familyFormValidation.values?.unesco}
                        name="unesco"
                        onChange={familyFormValidation.handleChange}
                        className={`custom-select ${familyFormValidation.touched.unesco && familyFormValidation.errors.unesco && 'is-invalid'}`}
                      >
                        {booleanOptions.map(e => (<option key={e.name} value={e.value}>{t(e.name)}</option>))}
                      </select>
                      {familyFormValidation.touched.unesco && familyFormValidation.errors.unesco && (<span className="error invalid-feedback">{familyFormValidation.errors.unesco}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="health_insurance">{t('Health Insurance')}</label>
                      <input
                        id="health_insurance"
                        name="health_insurance"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.health_insurance}
                        className={`form-control ${familyFormValidation.touched.health_insurance && familyFormValidation.errors.health_insurance && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.health_insurance && familyFormValidation.errors.health_insurance && (<span className="error invalid-feedback">{familyFormValidation.errors.health_insurance}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="breadwinner">{t('Breadwinner')}</label>
                      <input
                        id="breadwinner"
                        name="breadwinner"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.breadwinner}
                        className={`form-control ${familyFormValidation.touched.breadwinner && familyFormValidation.errors.breadwinner && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.breadwinner && familyFormValidation.errors.breadwinner && (<span className="error invalid-feedback">{familyFormValidation.errors.breadwinner}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="id_picture">{t('Picture Of ID')}</label>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="id_picture"
                          name="id_picture"
                          onChange={compressImage(setIdPicture)}
                        />
                        <span className="file-input-name-preview col-md-3" />
                        <label className="custom-file-label" htmlFor="customFile" value="A&A" />
                      </div>
                    </div>
                    <div className="form-group col-md-3">
                      <img
                        src={idPicture || imagePlaceholder}
                        alt=""
                        className="img-thumbnail bg-light"
                      />
                    </div>
                  </div>
                  <h4>{t('Residency Info')}</h4>
                  <div className="form-row border-bottom border-secondary mb-3">
                    <div className="form-group col-md-3">
                      <label htmlFor="region">{t('Region')}</label>
                      <input
                        id="region"
                        name="region"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.region}
                        className={`form-control ${familyFormValidation.touched.region && familyFormValidation.errors.region && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.region && familyFormValidation.errors.region && (<span className="error invalid-feedback">{familyFormValidation.errors.region}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="street">{t('Street')}</label>
                      <input
                        id="street"
                        name="street"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.street}
                        className={`form-control ${familyFormValidation.touched.street && familyFormValidation.errors.street && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.street && familyFormValidation.errors.street && (<span className="error invalid-feedback">{familyFormValidation.errors.street}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="building">{t('Building')}</label>
                      <input
                        id="building"
                        name="building"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.building}
                        className={`form-control ${familyFormValidation.touched.building && familyFormValidation.errors.building && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.building && familyFormValidation.errors.building && (<span className="error invalid-feedback">{familyFormValidation.errors.building}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="beside">{t('Beside')}</label>
                      <input
                        id="beside"
                        name="beside"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.beside}
                        className={`form-control ${familyFormValidation.touched.beside && familyFormValidation.errors.beside && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.beside && familyFormValidation.errors.beside && (<span className="error invalid-feedback">{familyFormValidation.errors.beside}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="floor">{t('Floor')}</label>
                      <input
                        id="floor"
                        name="floor"
                        type="number"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.floor}
                        className={`form-control ${familyFormValidation.touched.floor && familyFormValidation.errors.floor && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.floor && familyFormValidation.errors.floor && (<span className="error invalid-feedback">{familyFormValidation.errors.floor}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="house_owner_name">{t('House Owner Name')}</label>
                      <input
                        id="house_owner_name"
                        name="house_owner_name"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.house_owner_name}
                        className={`form-control ${familyFormValidation.touched.house_owner_name && familyFormValidation.errors.house_owner_name && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.house_owner_name && familyFormValidation.errors.house_owner_name && (<span className="error invalid-feedback">{familyFormValidation.errors.house_owner_name}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="housing_status">
                        {t('Housing Status')}
                      </label>
                      <select
                        value={familyFormValidation.values?.housing_status}
                        name="housing_status"
                        onChange={familyFormValidation.handleChange}
                        className={`custom-select ${familyFormValidation.touched.housing_status && familyFormValidation.errors.housing_status && 'is-invalid'}`}
                      >
                        <option value="" selected disabled>{t('Choose type of property')}</option>
                        {['Rented', 'Owned', 'Mashaa'].map(e => (<option key={e} value={t(e, { lng: 'ar' })}>{t(e)}</option>))}
                      </select>
                      {familyFormValidation.touched.housing_status && familyFormValidation.errors.housing_status && (<span className="error invalid-feedback">{familyFormValidation.errors.housing_status}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="rent_value">{t('Rent Value')}</label>
                      <input
                        id="rent_value"
                        name="rent_value"
                        type="number"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.rent_value}
                        className={`form-control ${familyFormValidation.touched.rent_value && familyFormValidation.errors.rent_value && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.rent_value && familyFormValidation.errors.rent_value && (<span className="error invalid-feedback">{familyFormValidation.errors.rent_value}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="rent_value_currency">
                        {t('Currency')}
                      </label>
                      <select
                        value={familyFormValidation.values?.rent_value_currency}
                        name="rent_value_currency"
                        onChange={familyFormValidation.handleChange}
                        className={`custom-select ${familyFormValidation.touched.rent_value_currency && familyFormValidation.errors.rent_value_currency && 'is-invalid'}`}
                      >
                        <option value="" selected disabled>{t('Choose Currency')}</option>
                        {['LBP', 'USD'].map(e => (<option key={e} value={t(e, { lng: 'ar' })}>{e}</option>))}
                      </select>
                      {familyFormValidation.touched.rent_value_currency && familyFormValidation.errors.rent_value_currency && (<span className="error invalid-feedback">{familyFormValidation.errors.rent_value_currency}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="number_of_rooms">{t('Number of Rooms')}</label>
                      <input
                        id="number_of_rooms"
                        name="number_of_rooms"
                        type="number"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.number_of_rooms}
                        className={`form-control ${familyFormValidation.touched.number_of_rooms && familyFormValidation.errors.number_of_rooms && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.number_of_rooms && familyFormValidation.errors.number_of_rooms && (<span className="error invalid-feedback">{familyFormValidation.errors.number_of_rooms}</span>)}
                    </div>
                  </div>
                  <h4>{t('Partner')}</h4>
                  <div className="form-row border-bottom border-secondary mb-3">
                    <div className="form-group col-md-3">
                      <label htmlFor="partner_full_name">
                        {t('Full Name')}
                      </label>
                      <input
                        id="partner_full_name"
                        name="partner_full_name"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.partner_full_name}
                        className={`form-control ${familyFormValidation.touched.partner_full_name && familyFormValidation.errors.partner_full_name && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.partner_full_name && familyFormValidation.errors.partner_full_name && (<span className="error invalid-feedback">{familyFormValidation.errors.partner_full_name}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="partner_mother_name">{t('Mother\'s Name')}</label>
                      <input
                        id="partner_mother_name"
                        name="partner_mother_name"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.partner_mother_name}
                        className={`form-control ${familyFormValidation.touched.partner_mother_name && familyFormValidation.errors.partner_mother_name && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.partner_mother_name && familyFormValidation.errors.partner_mother_name && (<span className="error invalid-feedback">{familyFormValidation.errors.partner_mother_name}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="partner_birth_date">{t('Date of birth')}</label>
                      <input
                        type="date"
                        id="partner_birth_date"
                        name="partner_birth_date"
                        autoComplete="off"
                        data-date="23 May 2022"
                        data-date-format="DD MMMM YYYY"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.partner_birth_date}
                        className={`form-control ${familyFormValidation.touched.partner_birth_date && familyFormValidation.errors.partner_birth_date && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.partner_birth_date && familyFormValidation.errors.partner_birth_date && (<span className="error invalid-feedback">{familyFormValidation.errors.partner_birth_date}</span>)}
                      <span className="focus-border" />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="partner_is_dead">
                        {t('Is dead?')}
                      </label>
                      <select
                        value={familyFormValidation.values?.partner_is_dead}
                        name="partner_is_dead"
                        onChange={familyFormValidation.handleChange}
                        className="custom-select"
                      >
                        {booleanOptions.map(e => (<option key={e.name} value={e.value}>{t(e.name)}</option>))}
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="partner_nationality">
                        {t('Nationality')}
                      </label>
                      <input
                        id="partner_nationality"
                        name="partner_nationality"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.partner_nationality}
                        className={`form-control ${familyFormValidation.touched.partner_nationality && familyFormValidation.errors.partner_nationality && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.partner_nationality && familyFormValidation.errors.partner_nationality && (<span className="error invalid-feedback">{familyFormValidation.errors.partner_nationality}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="partner_record_number">{t('Record Number')}</label>
                      <input
                        id="partner_record_number"
                        name="partner_record_number"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.partner_record_number}
                        className={`form-control ${familyFormValidation.touched.partner_record_number && familyFormValidation.errors.partner_record_number && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.partner_record_number && familyFormValidation.errors.partner_record_number && (<span className="error invalid-feedback">{familyFormValidation.errors.partner_record_number}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="partner_record_place">{t('Record Place')}</label>
                      <input
                        id="partner_record_place"
                        name="partner_record_place"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.partner_record_place}
                        className={`form-control ${familyFormValidation.touched.partner_record_place && familyFormValidation.errors.partner_record_place && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.partner_record_place && familyFormValidation.errors.partner_record_place && (<span className="error invalid-feedback">{familyFormValidation.errors.partner_record_place}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="partner_gender">{t('Gender')}</label>
                      <select
                        name="partner_gender"
                        value={familyFormValidation.values?.partner_gender}
                        onChange={familyFormValidation.handleChange}
                        className={`custom-select ${familyFormValidation.touched.partner_gender && familyFormValidation.errors.partner_gender && 'is-invalid'}`}
                      >
                        <option value="" selected disabled>{t('Choose Gender')}</option>
                        {genders.map(e => (<option key={e} value={t(e, { lng: 'ar' })}>{t(e)}</option>))}
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="partner_work">{t('Work')}</label>
                      <input
                        id="partner_work"
                        name="partner_work"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.partner_work}
                        className={`form-control ${familyFormValidation.touched.partner_work && familyFormValidation.errors.partner_work && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.partner_work && familyFormValidation.errors.partner_work && (<span className="error invalid-feedback">{familyFormValidation.errors.partner_work}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="partner_medical_status">{t('Medical Status')}</label>
                      <input
                        id="partner_medical_status"
                        name="partner_medical_status"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.partner_medical_status}
                        className={`form-control ${familyFormValidation.touched.partner_medical_status && familyFormValidation.errors.partner_medical_status && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.partner_medical_status && familyFormValidation.errors.partner_medical_status && (<span className="error invalid-feedback">{familyFormValidation.errors.partner_medical_status}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="partner_sickness_type">{t('Sickness Type')}</label>
                      <input
                        id="partner_sickness_type"
                        name="partner_sickness_type"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.partner_sickness_type}
                        className={`form-control ${familyFormValidation.touched.partner_sickness_type && familyFormValidation.errors.partner_sickness_type && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.partner_sickness_type && familyFormValidation.errors.partner_sickness_type && (<span className="error invalid-feedback">{familyFormValidation.errors.partner_sickness_type}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="partner_disability_type">{t('Disability Type')}</label>
                      <input
                        id="partner_disability_type"
                        name="partner_disability_type"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.partner_disability_type}
                        className={`form-control ${familyFormValidation.touched.partner_disability_type && familyFormValidation.errors.partner_disability_type && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.partner_disability_type && familyFormValidation.errors.partner_disability_type && (<span className="error invalid-feedback">{familyFormValidation.errors.partner_disability_type}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="partner_social_security">
                        {t('Social Security')}
                      </label>
                      <select
                        value={familyFormValidation.values?.partner_social_security}
                        name="partner_social_security"
                        onChange={familyFormValidation.handleChange}
                        className={`custom-select ${familyFormValidation.touched.partner_social_security && familyFormValidation.errors.partner_social_security && 'is-invalid'}`}
                      >
                        {booleanOptions.map(e => (<option key={e.name} value={e.value}>{t(e.name)}</option>))}
                      </select>
                      {familyFormValidation.touched.partner_social_security && familyFormValidation.errors.partner_social_security && (<span className="error invalid-feedback">{familyFormValidation.errors.partner_social_security}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="partner_health_insurance">{t('Health Insurance')}</label>
                      <input
                        id="partner_health_insurance"
                        name="partner_health_insurance"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.partner_health_insurance}
                        className={`form-control ${familyFormValidation.touched.partner_health_insurance && familyFormValidation.errors.partner_health_insurance && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.partner_health_insurance && familyFormValidation.errors.partner_health_insurance && (<span className="error invalid-feedback">{familyFormValidation.errors.partner_health_insurance}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="partner_social_status">{t('Social Status')}</label>
                      <input
                        id="partner_social_status"
                        name="partner_social_status"
                        type="text"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.partner_social_status}
                        className={`form-control ${familyFormValidation.touched.partner_social_status && familyFormValidation.errors.partner_social_status && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.partner_social_status && familyFormValidation.errors.partner_social_status && (<span className="error invalid-feedback">{familyFormValidation.errors.partner_social_status}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="partner_phone_number">
                        {t('Phone')}
                      </label>
                      <input
                        id="partner_phone_number"
                        type="text"
                        name="partner_phone_number"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.partner_phone_number}
                        className={`form-control ${familyFormValidation.touched.partner_phone_number && familyFormValidation.errors.partner_phone_number && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.partner_phone_number && familyFormValidation.errors.partner_phone_number && (<span className="error invalid-feedback">{familyFormValidation.errors.partner_phone_number}</span>)}
                    </div>
                  </div>
                  <h4>{t('Family Members')}</h4>
                  <div className="border-bottom border-secondary mb-3">
                    {
                      familyMembers.map((member, i) => (
                        <div key={i}>
                          <div className="form-row mt-2 pl-0 border-top pt-2 mb-1">
                            <div className="col">
                              <h5>
                                {t('Child')}
                                {' '}
                                {i + 1}
                              </h5>
                            </div>
                            <div className="col">
                              <button type="button" className="btn btn-block btn-danger" onClick={() => removeMember(i)}>
                                <i className="fas fa-times" />
                              </button>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-3">
                              <label htmlFor={`name_${i}`}>{t('Name')}</label>
                              <input
                                type="text"
                                id={`name_${i}`}
                                onChange={handleMemberInputChange(i, 'name')}
                                value={member.name}
                                className={`form-control ${familyMembersInputErrors[i]?.name ? 'is-invalid' : null}`}
                              />
                              {familyMembersInputErrors[i]?.name && (<span className="error invalid-feedback">Name is invalid</span>)}
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor={`birth_date_${i}`}>{t('Date of birth')}</label>
                              <input
                                type="date"
                                id={`birth_date_${i}`}
                                autoComplete="off"
                                data-date="23 May 2022"
                                data-date-format="MM DD YYYY"
                                onChange={handleMemberInputChange(i, 'birth_date')}
                                value={member.birth_date}
                                className="form-control datetimepicker-input"
                              />
                              <span className="focus-border" />
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor={`gender_${i}`}>{t('Gender')}</label>
                              <select
                                name={`gender_${i}`}
                                onChange={handleMemberInputChange(i, 'gender')}
                                value={member.gender}
                                className="custom-select"
                              >
                                <option value="" selected disabled>{t('Choose Gender')}</option>
                                {genders.map(e => (<option key={e} value={t(e, { lng: 'ar' })}>{t(e)}</option>))}
                              </select>
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor={`social_status_${i}`}>{t('Social Status')}</label>
                              <input
                                type="text"
                                id={`social_status_${i}`}
                                onChange={handleMemberInputChange(i, 'social_status')}
                                value={member.social_status}
                                className={`form-control ${familyMembersInputErrors[i]?.social_status ? 'is-invalid' : null}`}
                              />
                              {familyMembersInputErrors[i]?.social_status && (<span className="error invalid-feedback">{t('Social Status is invalid')}</span>)}
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor={`education_level_${i}`}>{t('Education Level')}</label>
                              <input
                                type="text"
                                id={`education_level_${i}`}
                                onChange={handleMemberInputChange(i, 'education_level')}
                                value={member.education_level}
                                className={`form-control ${familyMembersInputErrors[i]?.education_level ? 'is-invalid' : null}`}
                              />
                              {familyMembersInputErrors[i]?.education_level && (<span className="error invalid-feedback">{t('Education Level is invalid')}</span>)}
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor={`education_institute_${i}`}>{t('Education Institute')}</label>
                              <input
                                type="text"
                                id={`education_institute_${i}`}
                                onChange={handleMemberInputChange(i, 'education_institute')}
                                value={member.education_institute}
                                className={`form-control ${familyMembersInputErrors[i]?.education_institute ? 'is-invalid' : null}`}
                              />
                              {familyMembersInputErrors[i]?.education_institute && (<span className="error invalid-feedback">{t('Education institute is invalid')}</span>)}
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor={`medical_status_${i}`}>{t('Medical Status')}</label>
                              <input
                                type="text"
                                id={`medical_status_${i}`}
                                onChange={handleMemberInputChange(i, 'medical_status')}
                                value={member.medical_status}
                                className={`form-control ${familyMembersInputErrors[i]?.medical_status ? 'is-invalid' : null}`}
                              />
                              {familyMembersInputErrors[i]?.medical_status && (<span className="error invalid-feedback">{t('Medical Status is invalid')}</span>)}
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor={`workers_${i}`}>{t('Workers')}</label>
                              <input
                                type="text"
                                id={`workers_${i}`}
                                onChange={handleMemberInputChange(i, 'workers')}
                                value={member.workers}
                                className={`form-control ${familyMembersInputErrors[i]?.workers ? 'is-invalid' : null}`}
                              />
                              {familyMembersInputErrors[i]?.workers && (<span className="error invalid-feedback">{t('Workers is invalid')}</span>)}
                            </div>
                          </div>
                        </div>
                      ))
                    }
                    <div className="mb-3">
                      <button
                        type="button"
                        className="btn btn-block btn-primary"
                        onClick={addMember}
                      >
                        <i className="fas fa-plus mr-2" />
                        {t('Add Member')}
                      </button>
                    </div>
                  </div>
                  <h4>{t('Donation')}</h4>
                  <div className="form-row border-bottom border-secondary mb-3">
                    <div className="form-group col-md-4">
                      <label htmlFor="donation">{t('Donation Type')}</label>
                      <select
                        name="donation"
                        value={familyFormValidation.values?.donation}
                        onChange={familyFormValidation.handleChange}
                        className={`custom-select ${familyFormValidation.touched.donation && familyFormValidation.errors.donation && 'is-invalid'}`}
                      >
                        <option value="" selected disabled>{t('Choose')}</option>
                        {donations.map(e => (<option key={e} value={t(e, { lng: 'ar' })}>{t(e)}</option>))}
                        <option value="other">{t('Add Type')}</option>
                      </select>
                      {(familyFormValidation.values?.donation === "other")
                        && (
                          <input
                            type="text"
                            onChange={(e) => setDonationType(e.target.value)}
                            value={donationType}
                            className="form-control mt-3"
                            placeholder={t('Donation Type')}
                          />
                        )}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="doners">{t('Doners (each doner on a separate line)')}</label>
                      <textarea
                        cols="0"
                        rows="6"
                        name="doners"
                        id="doners"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.doners}
                        className={`form-control ${familyFormValidation.touched.doners && familyFormValidation.errors.doners && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.doners && familyFormValidation.errors.doners && (<span className="error invalid-feedback">{familyFormValidation.errors.doners}</span>)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label htmlFor="notes">{t('Notes')}</label>
                      <textarea
                        cols="0"
                        rows="6"
                        name="notes"
                        id="notes"
                        onChange={familyFormValidation.handleChange}
                        value={familyFormValidation.values?.notes}
                        className={`form-control ${familyFormValidation.touched.notes && familyFormValidation.errors.notes && 'is-invalid'}`}
                      />
                      {familyFormValidation.touched.notes && familyFormValidation.errors.notes && (<span className="error invalid-feedback">{familyFormValidation.errors.notes}</span>)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col d-flex justify-content-center">
                      <button type="submit" className="btn btn-primary">{t('Save')}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>

  )
}
