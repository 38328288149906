import React from 'react'
import { useSelector } from 'react-redux'

export default function Loader() {
  const isLoading = useSelector((state) => state?.loader?.loading)
  return isLoading ? (
    <div className="request-spinner-wrapper">
      <div className="request-spinner">
        <div className="lds-roller">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  ) : null
}
