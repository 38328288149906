import { ContentHeader, MedicalCasesTable, Toast } from 'components'
import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next';

export default function MedicalCases() {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [toastOpen, setToastOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const openModal = () => navigate('/medical-cases/create')
  const refreshTable = useRef(null)

  const editMedicalCaseHandler = (c) => {
    navigate(`/medical-cases/edit/${c.id}`)
  }

  return (
    <>
      <Toast open={toastOpen} onClose={() => setToastOpen(false)} message={errorMessage} type="error" />
      <ContentHeader title={t('Medical Cases')} hasAdd handleModal={openModal} />
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <MedicalCasesTable
                    handleEdit={editMedicalCaseHandler}
                    handleError={setErrorMessage}
                    refresh={refreshTable}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
