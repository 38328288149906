import React from 'react'
import {
  // BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom'

import { LoginScreen } from 'screens'

function MainRouter() {
  return (
    <Routes>
      <Route path="/" element={<LoginScreen />} />
    </Routes>
  )
}

export default MainRouter
