import { addUser, updateUser } from 'api/users'
import { getOrganizations } from 'api/organizations'
import {
  ContentHeader, UsersTable, CustomModal, Toast
} from 'components'
import React, { useEffect, useState, useRef } from 'react'
import { showLoader, hideLoader } from 'redux/slices/loader'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

export default function Users() {
  const myOrganization = JSON.parse(localStorage.getItem('user'))?.organization_id
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [organizations, setOrganizations] = useState([])
  const roles = ['organizer', 'worker']
  const [user, setUser] = useState({
    name: '',
    username: '',
    password: '',
    organization_id: myOrganization || '',
    role: ''
  })
  const [toastOpen, setToastOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const refreshTable = useRef(null)

  const closeModal = () => {
    setCreateModalOpen(false)
    setUser({
      name: '',
      username: '',
      password: '',
      organization_id: myOrganization || '',
      role: ''
    })
  }

  const editUserModal = (e) => {
    setUser({
      ...e,
      password: ''
    })
    setEditModalOpen(true)
  }

  useEffect(() => {
    getOrganizations({ limit: 20, offset: 0 })
      .then((response) => {
        setOrganizations(response.data.data.rows)
      })
      .catch((response) => {
        setErrorMessage('Internal Server Error!')
      })
  }, [])

  const createFormValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      username: '',
      password: '',
      organization_id: myOrganization || '',
      role: ''
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(3, t('Must be at least 3 characters'))
        .max(25, 'Max 25 chars')
        .required(t('Please Enter Your Name')),
      username: Yup.string()
        .min(3, t('Must be at least 3 characters'))
        .max(25, 'Max 25 chars')
        .required(t('Please Enter Your Username')),
      password: Yup.string()
        .min(8, 'Must be at least 8 characters')
        .max(25, 'Max 25 chars')
        .required(t('Please Enter Your Password')),
      organization_id: Yup.number()
        .positive()
        .integer()
        .required(t('Please Select an Organization')),
      role: Yup.string()
        .oneOf(roles)
        .required(t('Please Select a Role')),
    }),
    onSubmit: (values) => {
      dispatch(showLoader())
      addUser(values)
        .then((response) => {
          dispatch(hideLoader())
          if (response.status === 200) {
            refreshTable.current()
            closeModal()
          }
        }).catch(response => {
          setErrorMessage('Internal Server Error!')
          dispatch(hideLoader())
        })
    }
  })

  const editFormValidation = useFormik({
    enableReinitialize: true,
    initialValues: user,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(3, t(t('Must be at least 3 characters')))
        .max(25, t('Max 25 chars'))
        .required(t(t('Please Enter Your Name'))),
      password: Yup.string()
        .min(8, t('Must be at least 8 characters'))
        .max(25, t('Max 25 chars')),
      organization_id: Yup.number()
        .positive()
        .integer()
        .required(t(t('Please Select an Organization'))),
      role: Yup.string()
        .oneOf(roles)
        .required(t(t('Please Select a Role'))),
    }),
    onSubmit: (values) => {
      dispatch(showLoader())
      updateUser(values, user.id)
        .then((response) => {
          dispatch(hideLoader())
          if (response.status === 200) {
            setEditModalOpen(false)
            refreshTable.current()
          }
        }).catch(response => {
          setErrorMessage(t('Internal Server Error!'))
          dispatch(hideLoader())
        })
    }
  })

  useEffect(() => {
    if (!toastOpen) {
      setErrorMessage('')
    }
  }, [toastOpen])

  return (
    <>
      <Toast open={toastOpen} onClose={() => setToastOpen(false)} message={errorMessage} type="error" />
      <ContentHeader title={t('Users')} hasAdd handleModal={() => setCreateModalOpen(true)} />
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <UsersTable
                    handleEdit={editUserModal}
                    handleError={setErrorMessage}
                    refresh={refreshTable}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CustomModal
        handleClose={() => setCreateModalOpen(false)}
        handleForm={createFormValidation.handleSubmit}
        headerText={t('Add User')}
        submitText={t('Add')}
        open={createModalOpen}
      >
        <form className="needs-validation">
          <div className="form-group">
            <label htmlFor="name">
              {t('Name')}
              <span className="text-danger">*</span>
            </label>
            <input
              id="name"
              name="name"
              onChange={createFormValidation.handleChange}
              value={createFormValidation.values?.name}
              autoComplete="off"
              type="text"
              className={`form-control ${createFormValidation.touched.name && createFormValidation.errors.name && 'is-invalid'}`}
            />
            {createFormValidation.touched.name && createFormValidation.errors.name && (<span className="error invalid-feedback">{createFormValidation.errors.name}</span>)}
          </div>
          <div className="form-group">
            <label htmlFor="name">
              {t('Username')}
              <span className="text-danger">*</span>
            </label>
            <input
              id="username"
              name="username"
              onChange={createFormValidation.handleChange}
              value={createFormValidation.values?.username}
              autoComplete="off"
              type="text"
              className={`form-control ${createFormValidation.touched.username && createFormValidation.errors.username && 'is-invalid'}`}
            />
            {createFormValidation.touched.username && createFormValidation.errors.username && (<span className="error invalid-feedback">{createFormValidation.errors.username}</span>)}
          </div>
          <div className="form-group">
            <label htmlFor="name">
              {t('Password')}
              <span className="text-danger">*</span>
            </label>
            <input
              id="password"
              name="password"
              onChange={createFormValidation.handleChange}
              value={createFormValidation.values?.password}
              autoComplete="off"
              type="password"
              className={`form-control ${createFormValidation.touched.password && createFormValidation.errors.password && 'is-invalid'}`}
            />
            {createFormValidation.touched.password && createFormValidation.errors.password && (<span className="error invalid-feedback">{createFormValidation.errors.password}</span>)}
          </div>
          {myOrganization ? null : (
            <div className="form-group">
              <label htmlFor="name">
                {t('Organization')}
                <span className="text-danger">*</span>
              </label>
              <select
                id="organization_id"
                name="organization_id"
                onChange={createFormValidation.handleChange}
                value={createFormValidation.values?.organization_id || -1}
                className={`custom-select ${createFormValidation.touched.organization_id && createFormValidation.errors.organization_id && 'is-invalid'}`}
              >
                <option value="-1" disabled>{t('Select Organization')}</option>
                {organizations.map(o => (<option value={o.id} key={o.id}>{o.name}</option>))}
              </select>
              {createFormValidation.touched.organization_id && createFormValidation.errors.organization_id && (<span className="error invalid-feedback">{createFormValidation.errors.organization_id}</span>)}
            </div>
          )}
          <div className="form-group">
            <label htmlFor="name">
              {t('Role')}
              <span className="text-danger">*</span>
            </label>
            <select
              id="role"
              name="role"
              onChange={createFormValidation.handleChange}
              value={createFormValidation.values?.role || -1}
              className={`custom-select ${createFormValidation.touched.role && createFormValidation.errors.role && 'is-invalid'}`}
            >
              <option value="-1" disabled>{t('Select Role')}</option>
              {roles.map(e => (<option key={e} value={e}>{t(e)}</option>))}
            </select>
            {createFormValidation.touched.role && createFormValidation.errors.role && (<span className="error invalid-feedback">{createFormValidation.errors.role}</span>)}
          </div>
        </form>
      </CustomModal>
      <CustomModal
        handleClose={() => setEditModalOpen(false)}
        handleForm={editFormValidation.handleSubmit}
        headerText={t('Edit User')}
        submitText={t('Update')}
        open={editModalOpen}
      >
        <form className="needs-validation">
          <div className="form-group">
            <label htmlFor="name">
              {t('Name')}
              <span className="text-danger">*</span>
            </label>
            <input
              id="name"
              name="name"
              onChange={editFormValidation.handleChange}
              value={editFormValidation.values?.name}
              autoComplete="off"
              type="text"
              className={`form-control ${editFormValidation.touched.name && editFormValidation.errors.name && 'is-invalid'}`}
            />
            {editFormValidation.touched.name && editFormValidation.errors.name && (<span className="error invalid-feedback">{editFormValidation.errors.name}</span>)}
          </div>
          <div className="form-group">
            <label htmlFor="name">
              {t('Username')}
              <span className="text-danger">*</span>
            </label>
            <input
              disabled
              type="text"
              value={editFormValidation.values?.username}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">
              {t('Password')}
              <span className="text-danger">*</span>
            </label>
            <input
              id="password"
              name="password"
              onChange={editFormValidation.handleChange}
              value={editFormValidation.values?.password}
              autoComplete="off"
              type="password"
              className={`form-control ${editFormValidation.touched.password && editFormValidation.errors.password && 'is-invalid'}`}
            />
            {editFormValidation.touched.password && editFormValidation.errors.password && (<span className="error invalid-feedback">{editFormValidation.errors.password}</span>)}
          </div>
          {myOrganization ? null : (
            <div className="form-group">
              <label htmlFor="name">
                {t('Organization')}
                <span className="text-danger">*</span>
              </label>
              <select
                id="organization_id"
                name="organization_id"
                onChange={editFormValidation.handleChange}
                value={editFormValidation.values?.organization_id || -1}
                className={`custom-select ${editFormValidation.touched.organization_id && editFormValidation.errors.organization_id && 'is-invalid'}`}
              >
                <option value="-1" disabled>{t('Select Organization')}</option>
                {organizations.map(o => (<option value={o.id} key={o.id}>{o.name}</option>))}
              </select>
              {editFormValidation.touched.organization_id && editFormValidation.errors.organization_id && (<span className="error invalid-feedback">{editFormValidation.errors.organization_id}</span>)}
            </div>
          )}
          <div className="form-group">
            <label htmlFor="name">
              {t('Role')}
              <span className="text-danger">*</span>
            </label>
            <select
              id="role"
              name="role"
              onChange={editFormValidation.handleChange}
              value={editFormValidation.values?.role || -1}
              className={`custom-select ${editFormValidation.touched.role && editFormValidation.errors.role && 'is-invalid'}`}
            >
              <option value="-1" disabled>{t('Select Role')}</option>
              {roles.map(e => (<option key={e} value={e}>{t(e)}</option>))}
            </select>
            {editFormValidation.touched.role && editFormValidation.errors.role && (<span className="error invalid-feedback">{editFormValidation.errors.role}</span>)}
          </div>
        </form>
      </CustomModal>
    </>
  )
}
