import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { addMedicalEquipment } from 'api/medicalEquipment'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { showLoader, hideLoader } from 'redux/slices/loader'
import compressImage from 'lib/imageCompress'
import imagePlaceholder from 'assets/image-placeholder.png'
import { Toast } from 'components'
import { useTranslation } from 'react-i18next'

export default function CreateMedicalEquipment() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [toastOpen, setToastOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [idPicture, setIdPicture] = useState(null)
  const [medicalEquipmentPicture, setMedicalEquipmentPicture] = useState(null)
  const bloodTypes = [t('A+'), t('A-'), t('B+'), t('B-'), t('O+'), t('O-'), t('AB+'), t('AB-')]
  const maritalStatuses = ['Single', 'Married', 'Divorced', 'Widowed']
  const medicalEquipmentsFormData = {
    full_name: null,
    birth_date: null,
    blood_type: null,
    marital_status: null,
    residency_location: null,
    nationality: null,
    phone_number: null,
    land_line: null,
    disability: null,
    medical_status: null,
    condition: null,
    medical_equipment: null,
    return_date: null,
  }
  const medicalEquipmentsFormValidation = useFormik({
    enableReinitialize: true,
    initialValues: medicalEquipmentsFormData,
    validationSchema: yup.object().shape({
      full_name: yup.string(t('Must provide Full Name')).trim().required(),
    }),
    onSubmit: (values) => {
      dispatch(showLoader())
      const data = { ...values }
      if (idPicture) {
        data.id_picture = idPicture
      }
      if (medicalEquipmentPicture) {
        data.medical_equipment_picture = medicalEquipmentPicture
      }
      addMedicalEquipment(data)
        .then((response) => {
          dispatch(hideLoader())
          if (response.status === 200) {
            navigate('/medical-equipment')
          }
        }).catch(response => {
          setErrorMessage('Internal Server Error!')
          dispatch(hideLoader())
        })
    }
  })

  useEffect(() => {
    if (errorMessage !== '') {
      setToastOpen(true)
    }
  }, [errorMessage])

  useEffect(() => {
    if (toastOpen === false) {
      setErrorMessage('')
    }
  }, [toastOpen])

  return (
    <>
      <Toast open={toastOpen} onClose={() => setToastOpen(false)} message={errorMessage} type="error" />
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{t('Add Medical Equipment')}</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <form
                  id="form"
                  encType="multipart/form-data"
                  onSubmit={medicalEquipmentsFormValidation.handleSubmit}
                >
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label htmlFor="full_name">
                        {t('Full Name')}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        id="full_name"
                        name="full_name"
                        type="text"
                        onChange={medicalEquipmentsFormValidation.handleChange}
                        value={medicalEquipmentsFormValidation.values?.full_name}
                        className={`form-control ${medicalEquipmentsFormValidation.touched.full_name && medicalEquipmentsFormValidation.errors.full_name && 'is-invalid'}`}
                      />
                      {medicalEquipmentsFormValidation.touched.full_name && medicalEquipmentsFormValidation.errors.full_name && (<span className="error invalid-feedback">{medicalEquipmentsFormValidation.errors.full_name}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="birthdate">{t('Date of birth')}</label>
                      <input
                        type="date"
                        id="birthdate"
                        name="birth_date"
                        autoComplete="off"
                        data-date="23 May 2022"
                        data-date-format="DD MMMM YYYY"
                        onChange={medicalEquipmentsFormValidation.handleChange}
                        value={medicalEquipmentsFormValidation.values?.birth_date}
                        className={`form-control ${medicalEquipmentsFormValidation.touched.birth_date && medicalEquipmentsFormValidation.errors.birth_date && 'is-invalid'}`}
                      />
                      {medicalEquipmentsFormValidation.touched.birth_date && medicalEquipmentsFormValidation.errors.birth_date && (<span className="error invalid-feedback">{medicalEquipmentsFormValidation.errors.birth_date}</span>)}
                      <span className="focus-border" />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="blood_type">
                        {t('Blood Type')}
                      </label>
                      <select
                        value={medicalEquipmentsFormValidation.values?.blood_type}
                        name="blood_type"
                        onChange={medicalEquipmentsFormValidation.handleChange}
                        className={`custom-select ${medicalEquipmentsFormValidation.touched.blood_type && medicalEquipmentsFormValidation.errors.blood_type && 'is-invalid'}`}
                      >
                        <option value="" selected disabled>{t('Choose')}</option>
                        {bloodTypes.map(e => (<option key={e} value={e}>{e}</option>))}
                      </select>
                      {medicalEquipmentsFormValidation.touched.blood_type && medicalEquipmentsFormValidation.errors.blood_type && (<span className="error invalid-feedback">{medicalEquipmentsFormValidation.errors.blood_type}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="marital_status">
                        {t('Marital Status')}
                      </label>
                      <select
                        value={medicalEquipmentsFormValidation.values?.marital_status}
                        name="marital_status"
                        onChange={medicalEquipmentsFormValidation.handleChange}
                        className={`custom-select ${medicalEquipmentsFormValidation.touched.marital_status && medicalEquipmentsFormValidation.errors.marital_status && 'is-invalid'}`}
                      >
                        <option value="" selected disabled>{t('Choose Marital Status')}</option>
                        {maritalStatuses.map(e => (<option key={e} value={t(e, { lng: 'ar' })}>{t(e)}</option>))}
                      </select>
                      {medicalEquipmentsFormValidation.touched.marital_status && medicalEquipmentsFormValidation.errors.marital_status && (<span className="error invalid-feedback">{medicalEquipmentsFormValidation.errors.marital_status}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="residency_location">{t('Location of residence')}</label>
                      <input
                        id="residency_location"
                        name="residency_location"
                        type="text"
                        onChange={medicalEquipmentsFormValidation.handleChange}
                        value={medicalEquipmentsFormValidation.values?.residency_location}
                        className={`form-control ${medicalEquipmentsFormValidation.touched.residency_location && medicalEquipmentsFormValidation.errors.residency_location && 'is-invalid'}`}
                      />
                      {medicalEquipmentsFormValidation.touched.residency_location && medicalEquipmentsFormValidation.errors.residency_location && (<span className="error invalid-feedback">{medicalEquipmentsFormValidation.errors.residency_location}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="nationality">
                        {t('Nationality')}
                      </label>
                      <input
                        id="nationality"
                        type="text"
                        name="nationality"
                        onChange={medicalEquipmentsFormValidation.handleChange}
                        value={medicalEquipmentsFormValidation.values?.nationality}
                        className={`form-control ${medicalEquipmentsFormValidation.touched.nationality && medicalEquipmentsFormValidation.errors.nationality && 'is-invalid'}`}
                      />
                      {medicalEquipmentsFormValidation.touched.nationality && medicalEquipmentsFormValidation.errors.nationality && (<span className="error invalid-feedback">{medicalEquipmentsFormValidation.errors.nationality}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="phone_number">
                        {t('Phone')}
                      </label>
                      <input
                        id="phone_number"
                        type="text"
                        name="phone_number"
                        onChange={medicalEquipmentsFormValidation.handleChange}
                        value={medicalEquipmentsFormValidation.values?.phone_number}
                        className={`form-control ${medicalEquipmentsFormValidation.touched.phone_number && medicalEquipmentsFormValidation.errors.phone_number && 'is-invalid'}`}
                      />
                      {medicalEquipmentsFormValidation.touched.phone_number && medicalEquipmentsFormValidation.errors.phone_number && (<span className="error invalid-feedback">{medicalEquipmentsFormValidation.errors.phone_number}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="land_line">
                        {t('Land Line')}
                      </label>
                      <input
                        id="land_line"
                        type="text"
                        name="land_line"
                        onChange={medicalEquipmentsFormValidation.handleChange}
                        value={medicalEquipmentsFormValidation.values?.land_line}
                        className={`form-control ${medicalEquipmentsFormValidation.touched.land_line && medicalEquipmentsFormValidation.errors.land_line && 'is-invalid'}`}
                      />
                      {medicalEquipmentsFormValidation.touched.land_line && medicalEquipmentsFormValidation.errors.land_line && (<span className="error invalid-feedback">{medicalEquipmentsFormValidation.errors.land_line}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="disability">{t('Disability')}</label>
                      <input
                        id="disability"
                        type="text"
                        name="disability"
                        onChange={medicalEquipmentsFormValidation.handleChange}
                        value={medicalEquipmentsFormValidation.values?.disability}
                        className={`form-control ${medicalEquipmentsFormValidation.touched.disability && medicalEquipmentsFormValidation.errors.disability && 'is-invalid'}`}
                      />
                      {medicalEquipmentsFormValidation.touched.disability && medicalEquipmentsFormValidation.errors.disability && (<span className="error invalid-feedback">{medicalEquipmentsFormValidation.errors.disability}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="medical_status">
                        {t('Medical Status')}
                      </label>
                      <input
                        id="medical_status"
                        type="text"
                        name="medical_status"
                        onChange={medicalEquipmentsFormValidation.handleChange}
                        value={medicalEquipmentsFormValidation.values?.medical_status}
                        className={`form-control ${medicalEquipmentsFormValidation.touched.medical_status && medicalEquipmentsFormValidation.errors.medical_status && 'is-invalid'}`}
                      />
                      {medicalEquipmentsFormValidation.touched.medical_status && medicalEquipmentsFormValidation.errors.medical_status && (<span className="error invalid-feedback">{medicalEquipmentsFormValidation.errors.medical_status}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="condition">
                        {t('Condition')}
                      </label>
                      <input
                        id="condition"
                        type="text"
                        name="condition"
                        onChange={medicalEquipmentsFormValidation.handleChange}
                        value={medicalEquipmentsFormValidation.values?.condition}
                        className={`form-control ${medicalEquipmentsFormValidation.touched.condition && medicalEquipmentsFormValidation.errors.condition && 'is-invalid'}`}
                      />
                      {medicalEquipmentsFormValidation.touched.condition && medicalEquipmentsFormValidation.errors.condition && (<span className="error invalid-feedback">{medicalEquipmentsFormValidation.errors.condition}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="typeOfAid">{t('Medical Equipment')}</label>
                      <input
                        type="text"
                        name="medical_equipment"
                        id="medical_equipment"
                        onChange={medicalEquipmentsFormValidation.handleChange}
                        value={medicalEquipmentsFormValidation.values?.medical_equipment}
                        className={`form-control ${medicalEquipmentsFormValidation.touched.medical_equipment && medicalEquipmentsFormValidation.errors.medical_equipment && 'is-invalid'}`}
                      />
                      {medicalEquipmentsFormValidation.touched.medical_equipment && medicalEquipmentsFormValidation.errors.medical_equipment && (<span className="error invalid-feedback">{medicalEquipmentsFormValidation.errors.medical_equipment}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="return_date">{t('Return Date')}</label>
                      <input
                        type="date"
                        id="return_date"
                        name="return_date"
                        autoComplete="off"
                        data-date="23 May 2022"
                        data-date-format="DD MMMM YYYY"
                        onChange={medicalEquipmentsFormValidation.handleChange}
                        value={medicalEquipmentsFormValidation.values?.return_date}
                        className={`form-control ${medicalEquipmentsFormValidation.touched.return_date && medicalEquipmentsFormValidation.errors.return_date && 'is-invalid'}`}
                      />
                      {medicalEquipmentsFormValidation.touched.return_date && medicalEquipmentsFormValidation.errors.return_date && (<span className="error invalid-feedback">{medicalEquipmentsFormValidation.errors.return_date}</span>)}
                      <span className="focus-border" />
                    </div>
                    <div className="form-group col-md-9" />
                    <div className="form-group col-md-3">
                      <label htmlFor="id_picture">{t('Picture Of ID')}</label>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="id_picture"
                          name="id_picture"
                          onChange={compressImage(setIdPicture)}
                        />
                        <span className="file-input-name-preview col-md-3" />
                        <label className="custom-file-label" htmlFor="customFile" />
                      </div>
                    </div>
                    <div className="form-group col-md-3">
                      <img
                        src={idPicture || imagePlaceholder}
                        alt=""
                        className="img-thumbnail bg-light"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="birth_certificate_picture">{t('Picture of Medical Equipment')}</label>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="birth_certificate_picture"
                          name="birth_certificate_picture"
                          onChange={compressImage(setMedicalEquipmentPicture)}
                        />
                        <span className="file-input-name-preview col-md-3" />
                        <label className="custom-file-label" htmlFor="customFile" />
                      </div>
                    </div>
                    <div className="form-group col-md-3">
                      <img
                        src={medicalEquipmentPicture || imagePlaceholder}
                        alt="..."
                        className="img-thumbnail bg-light"
                      />
                    </div>
                    <div className="form-group col-md-3" />
                  </div>
                  <div className="row">
                    <div className="col d-flex justify-content-center">
                      <button type="submit" className="btn btn-primary">{t('Save')}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
