import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { showLoader, hideLoader } from 'redux/slices/loader'
import { Toast } from 'components'
import { useTranslation } from 'react-i18next'
import { addEmergency } from 'api/emergencies'

export default function CreateEmergency() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const genders = ['Male', 'Female']
  const ageGroups = ['Child', 'Teen', 'Adult', 'Elderly']
  const relations = ['Father', 'Mother', 'Son', 'Daughter', 'Brother', 'Sister', 'Husband', 'Wife', 'Grandfather', 'Grandmother', 'Grandson', 'Granddaughter', 'Uncle', 'Aunt', 'Nephew', 'Niece', 'Cousin']
  const items = ['Foodstuff', 'Matresses', 'Pillows', 'Blankets', 'Hygiene Kit', 'Hygiene Kit', 'Clothes', 'Shoes', 'Milk', 'Diapers', 'PSS', 'Activities']
  const navigate = useNavigate()
  const [toastOpen, setToastOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [emergencyFamilyMembers, setEmergencyFamilyMembers] = useState([])
  const [emergencyFamilyMembersInputErrors, setEmergencyFamilyMembersInputErrors] = useState([])
  const [emergencyItems, setEmergencyItems] = useState([])
  const [customEmergencyItems, setCustomEmergencyItems] = useState([])
  const [emergencyItemsInputErrors, setEmergencyItemsInputErrors] = useState([])
  const emergencyFormData = {
    head_of_household: null,
    first_name: null,
    middle_name: null,
    last_name: null,
    age: null,
    genrder: null,
    nationality: null,
    phone_number: null,
    original_residence: null,
    place_of_displacements: null,
    current_residence: null,
  }
  const emergencyFormValidation = useFormik({
    enableReinitialize: true,
    initialValues: emergencyFormData,
    validationSchema: yup.object().shape({
      first_name: yup.string(t('Must provide First Name')).trim().required(),
    }),
    onSubmit: (values) => {
      dispatch(showLoader())
      const data = { ...values }
      data.emergency_family_members = emergencyFamilyMembers
      data.emergency_items = emergencyItems.map((item, index) => {
        if (item.item === 'other') {
          return {
            ...item,
            item: customEmergencyItems[index]
          }
        }
        return {
          ...item,
        }
      })
      addEmergency(data)
        .then((response) => {
          dispatch(hideLoader())
          if (response.status === 200) {
            navigate('/emergencies')
          }
        }).catch(response => {
          setErrorMessage('Internal Server Error!')
          dispatch(hideLoader())
        })
    }
  })

  useEffect(() => {
    if (errorMessage !== null) {
      setToastOpen(true)
    }
  }, [errorMessage])

  useEffect(() => {
    if (toastOpen === false) {
      setErrorMessage(null)
    }
  }, [toastOpen])

  const addMember = () => {
    setEmergencyFamilyMembers(old => [
      ...old,
      {
        name: null,
        birth_date: null,
        gender: null,
        social_status: null,
        education_level: null,
        education_institute: null,
        medical_status: null,
        workers: null
      }])
    setEmergencyFamilyMembersInputErrors(old => [
      ...old,
      {
        name: false,
        birth_date: false,
        gender: false,
        social_status: false,
        education_level: false,
        education_institute: false,
        medical_status: false,
        workers: false
      }])
  }
  const addItem = () => {
    setEmergencyItems(old => [
      ...old,
      {
        item: "######",
        number_needed: null,
        number_provided: null,
        notes: null,
      }])
    setCustomEmergencyItems(old => [
      ...old,
      ""])
    setEmergencyItemsInputErrors(old => [
      ...old,
      {
        item: null,
        number_needed: null,
        number_provided: null,
        notes: null,
      }])
  }

  const removeMember = (index) => {
    let f = [...emergencyFamilyMembers]
    f.splice(index, 1)
    setEmergencyFamilyMembers(f)
    f = [...emergencyFamilyMembersInputErrors]
    f.splice(index, 1)
    setEmergencyFamilyMembersInputErrors(f)
  }

  const removeItem = (index) => {
    let f = [...emergencyItems]
    f.splice(index, 1)
    setEmergencyItems(f)
    f = [...emergencyItemsInputErrors]
    f.splice(index, 1)
    setEmergencyItemsInputErrors(f)
    f = [...customEmergencyItems]
    f.splice(index, 1)
    setCustomEmergencyItems(f)
  }

  const handleMemberInputChange = (index, key) => (e) => {
    const { value } = e.target
    setEmergencyFamilyMembers(
      emergencyFamilyMembers.map((member, i) => (
        i === index ? { ...member, [key]: value } : member
      ))
    )
  }
  const handleItemInputChange = (index, key) => (e) => {
    const { value } = e.target
    setEmergencyItems(
      emergencyItems.map((member, i) => (
        i === index ? { ...member, [key]: value } : member
      ))
    )
  }

  const handleCustomItemInputChange = (index) => (e) => {
    const { value } = e.target
    setCustomEmergencyItems(
      customEmergencyItems.map((item, i) => (
        i === index ? value : item
      ))
    )
  }

  return (
    <>
      <Toast open={toastOpen} onClose={() => setToastOpen(false)} message={errorMessage} type="error" />
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{t('Add Emergency')}</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <form
                  id="form"
                  encType="multipart/form-data"
                  onSubmit={emergencyFormValidation.handleSubmit}
                >
                  <h4>{t('Household Information')}</h4>
                  <div className="form-row border-bottom border-secondary mb-3">
                    <div className="form-group col-md-3">
                      <label htmlFor="head_of_household">
                        {t('Head of Household')}
                      </label>
                      <input
                        id="head_of_household"
                        name="head_of_household"
                        type="text"
                        onChange={emergencyFormValidation.handleChange}
                        value={emergencyFormValidation.values?.head_of_household}
                        className={`form-control ${emergencyFormValidation.touched.head_of_household && emergencyFormValidation.errors.head_of_household && 'is-invalid'}`}
                      />
                      {emergencyFormValidation.touched.head_of_household && emergencyFormValidation.errors.head_of_household && (<span className="error invalid-feedback">{emergencyFormValidation.errors.head_of_household}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="first_name">
                        {t('First Name')}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        id="first_name"
                        name="first_name"
                        type="text"
                        onChange={emergencyFormValidation.handleChange}
                        value={emergencyFormValidation.values?.first_name}
                        className={`form-control ${emergencyFormValidation.touched.first_name && emergencyFormValidation.errors.first_name && 'is-invalid'}`}
                      />
                      {emergencyFormValidation.touched.first_name && emergencyFormValidation.errors.first_name && (<span className="error invalid-feedback">{emergencyFormValidation.errors.first_name}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="middle_name">
                        {t('Middle Name')}
                      </label>
                      <input
                        id="middle_name"
                        name="middle_name"
                        type="text"
                        onChange={emergencyFormValidation.handleChange}
                        value={emergencyFormValidation.values?.middle_name}
                        className={`form-control ${emergencyFormValidation.touched.middle_name && emergencyFormValidation.errors.middle_name && 'is-invalid'}`}
                      />
                      {emergencyFormValidation.touched.middle_name && emergencyFormValidation.errors.middle_name && (<span className="error invalid-feedback">{emergencyFormValidation.errors.middle_name}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="last_name">
                        {t('Last Name')}
                      </label>
                      <input
                        id="last_name"
                        name="last_name"
                        type="text"
                        onChange={emergencyFormValidation.handleChange}
                        value={emergencyFormValidation.values?.last_name}
                        className={`form-control ${emergencyFormValidation.touched.last_name && emergencyFormValidation.errors.last_name && 'is-invalid'}`}
                      />
                      {emergencyFormValidation.touched.last_name && emergencyFormValidation.errors.last_name && (<span className="error invalid-feedback">{emergencyFormValidation.errors.last_name}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="age">{t('Age')}</label>
                      <input
                        id="age"
                        name="age"
                        type="text"
                        onChange={emergencyFormValidation.handleChange}
                        value={emergencyFormValidation.values?.age}
                        className={`form-control ${emergencyFormValidation.touched.age && emergencyFormValidation.errors.age && 'is-invalid'}`}
                      />
                      {emergencyFormValidation.touched.age && emergencyFormValidation.errors.age && (<span className="error invalid-feedback">{emergencyFormValidation.errors.age}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="gender">{t('Gender')}</label>
                      <select
                        name="gender"
                        value={emergencyFormValidation.values?.gender}
                        onChange={emergencyFormValidation.handleChange}
                        className={`custom-select ${emergencyFormValidation.touched.gender && emergencyFormValidation.errors.gender && 'is-invalid'}`}
                      >
                        <option value="" selected disabled>{t('Choose Gender')}</option>
                        {genders.map(e => (<option key={e} value={t(e, { lng: 'ar' })}>{t(e)}</option>))}
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="nationality">
                        {t('Nationality')}
                      </label>
                      <input
                        id="nationality"
                        name="nationality"
                        type="text"
                        onChange={emergencyFormValidation.handleChange}
                        value={emergencyFormValidation.values?.nationality}
                        className={`form-control ${emergencyFormValidation.touched.nationality && emergencyFormValidation.errors.nationality && 'is-invalid'}`}
                      />
                      {emergencyFormValidation.touched.nationality && emergencyFormValidation.errors.nationality && (<span className="error invalid-feedback">{emergencyFormValidation.errors.nationality}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="phone_number">{t('Phone Number')}</label>
                      <input
                        id="phone_number"
                        name="phone_number"
                        type="text"
                        onChange={emergencyFormValidation.handleChange}
                        value={emergencyFormValidation.values?.phone_number}
                        className={`form-control ${emergencyFormValidation.touched.phone_number && emergencyFormValidation.errors.phone_number && 'is-invalid'}`}
                      />
                      {emergencyFormValidation.touched.phone_number && emergencyFormValidation.errors.phone_number && (<span className="error invalid-feedback">{emergencyFormValidation.errors.phone_number}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="original_residence">{t('Original Residence')}</label>
                      <input
                        id="original_residence"
                        name="original_residence"
                        type="text"
                        onChange={emergencyFormValidation.handleChange}
                        value={emergencyFormValidation.values?.original_residence}
                        className={`form-control ${emergencyFormValidation.touched.original_residence && emergencyFormValidation.errors.original_residence && 'is-invalid'}`}
                      />
                      {emergencyFormValidation.touched.original_residence && emergencyFormValidation.errors.original_residence && (<span className="error invalid-feedback">{emergencyFormValidation.errors.original_residence}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="place_of_displacement">{t('Place of Displacement')}</label>
                      <input
                        id="place_of_displacement"
                        name="place_of_displacement"
                        type="text"
                        onChange={emergencyFormValidation.handleChange}
                        value={emergencyFormValidation.values?.place_of_displacement}
                        className={`form-control ${emergencyFormValidation.touched.place_of_displacement && emergencyFormValidation.errors.place_of_displacement && 'is-invalid'}`}
                      />
                      {emergencyFormValidation.touched.place_of_displacement && emergencyFormValidation.errors.place_of_displacement && (<span className="error invalid-feedback">{emergencyFormValidation.errors.place_of_displacement}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="current_residence">
                        {t('Current Residence')}
                      </label>
                      <input
                        id="current_residence"
                        type="text"
                        name="current_residence"
                        onChange={emergencyFormValidation.handleChange}
                        value={emergencyFormValidation.values?.current_residence}
                        className={`form-control ${emergencyFormValidation.touched.current_residence && emergencyFormValidation.errors.current_residence && 'is-invalid'}`}
                      />
                      {emergencyFormValidation.touched.current_residence && emergencyFormValidation.errors.current_residence && (<span className="error invalid-feedback">{emergencyFormValidation.errors.current_residence}</span>)}
                    </div>
                  </div>
                  <h4>{t('Family Information')}</h4>
                  <div className="border-bottom border-secondary mb-3">
                    {
                      emergencyFamilyMembers.map((member, i) => (
                        <div key={i}>
                          <div className="form-row mt-2 pl-0 border-top pt-2 mb-1">
                            <div className="col">
                              <h5>
                                {t('Member')}
                                {' '}
                                {i + 1}
                              </h5>
                            </div>
                            <div className="col">
                              <button type="button" className="btn btn-block btn-danger" onClick={() => removeMember(i)}>
                                <i className="fas fa-times" />
                              </button>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-3">
                              <label htmlFor={`age_group_${i}`}>{t('Age Group')}</label>
                              <select
                                name={`age_group_${i}`}
                                onChange={handleMemberInputChange(i, 'age_group')}
                                value={member.age_group}
                                className="custom-select"
                              >
                                <option value="" selected disabled>{t('Choose Age Group')}</option>
                                {ageGroups.map(e => (<option key={e} value={t(e, { lng: 'ar' })}>{t(e)}</option>))}
                              </select>
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor={`gender_${i}`}>{t('Gender')}</label>
                              <select
                                name={`gender_${i}`}
                                onChange={handleMemberInputChange(i, 'gender')}
                                value={member.gender}
                                className="custom-select"
                              >
                                <option value="" selected disabled>{t('Choose Gender')}</option>
                                {genders.map(e => (<option key={e} value={t(e, { lng: 'ar' })}>{t(e)}</option>))}
                              </select>
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor={`relation_${i}`}>{t('Relation')}</label>
                              <select
                                name={`relation_${i}`}
                                onChange={handleMemberInputChange(i, 'relation')}
                                value={member.relation}
                                className="custom-select"
                              >
                                <option value="" selected disabled>{t('Choose Gender')}</option>
                                {relations.map(e => (<option key={e} value={t(e, { lng: 'ar' })}>{t(e)}</option>))}
                              </select>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                    <div className="mb-3">
                      <button
                        type="button"
                        className="btn btn-block btn-primary"
                        onClick={addMember}
                      >
                        <i className="fas fa-plus mr-2" />
                        {t('Add Member')}
                      </button>
                    </div>
                  </div>
                  <h4>{t('Items')}</h4>
                  <div className="border-bottom border-secondary mb-3">
                    {
                      emergencyItems.map((item, i) => (
                        <div key={i}>
                          <div className="form-row mt-2 pl-0 border-top pt-2 mb-1">
                            <div className="col">
                              <h5>
                                {t('Item')}
                                {' '}
                                {i + 1}
                              </h5>
                            </div>
                            <div className="col">
                              <button type="button" className="btn btn-block btn-danger" onClick={() => removeItem(i)}>
                                <i className="fas fa-times" />
                              </button>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-3">
                              <label htmlFor={`item_${i}`}>{t('Item')}</label>
                              <select
                                name={`item_${i}`}
                                onChange={handleItemInputChange(i, 'item')}
                                value={item.item}
                                className={`custom-select ${emergencyItemsInputErrors[i]?.workers ? 'is-invalid' : null}`}
                              >
                                <option value="######" selected disabled>{t('Choose Item')}</option>
                                {items.map(e => (<option key={e} value={t(e, { lng: 'ar' })}>{t(e)}</option>))}
                                <option value="other">{t('Other')}</option>
                              </select>
                              {(![...items, "######"].includes(t(item?.item, { lng: 'ar' })))
                                && (
                                  <input
                                    type="text"
                                    onChange={handleCustomItemInputChange(i)}
                                    value={customEmergencyItems[i]}
                                    className="form-control mt-3"
                                    placeholder={t('Item')}
                                  />
                                )}
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor={`number_needed_${i}`}>{t('Number Needed')}</label>
                              <input
                                type="number"
                                id={`number_needed_${i}`}
                                onChange={handleItemInputChange(i, 'number_needed')}
                                value={item.number_needed}
                                className={`form-control ${emergencyItemsInputErrors[i]?.number_needed ? 'is-invalid' : null}`}
                              />
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor={`number_provided_${i}`}>{t('Number Provided')}</label>
                              <input
                                type="number"
                                id={`number_provided_${i}`}
                                onChange={handleItemInputChange(i, 'number_provided')}
                                value={item.number_provided}
                                className={`form-control ${emergencyItemsInputErrors[i]?.number_provided ? 'is-invalid' : null}`}
                              />
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor={`number_remaining_${i}`}>{t('Number Remaining')}</label>
                              <input
                                type="number"
                                id={`number_remaining_${i}`}
                                value={item.number_needed - item.number_provided}
                                className="form-control"
                                disabled
                              />
                            </div>
                            <div className="form-group col-md-6 ml-auto">
                              <label htmlFor={`notes_${i}`}>{t('Notes')}</label>
                              <textarea
                                cols="0"
                                rows="4"
                                id={`notes_${i}`}
                                onChange={handleItemInputChange(i, 'notes')}
                                value={item.notes}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      ))
                    }
                    <div className="mb-3">
                      <button
                        type="button"
                        className="btn btn-block btn-primary"
                        onClick={addItem}
                      >
                        <i className="fas fa-plus mr-2" />
                        {t('Add Item')}
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col d-flex justify-content-center">
                      <button type="submit" className="btn btn-primary">{t('Save')}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
