import { Wrapper } from 'components'
import React from 'react'
import {
  Route, Routes, Navigate
} from 'react-router-dom'
import {
  Dashboard, Organizations, Users
} from 'screens'

// Import Student Scholarship
import StudentScholarships from 'screens/scholarships/index'
import CreateStudentScholarship from 'screens/scholarships/create'
import EditStudentScholarship from 'screens/scholarships/edit'

// Import Family
import Families from 'screens/families/index'
import CreateFamily from 'screens/families/create'
import EditFamily from 'screens/families/edit'

// Medical Cases
import MedicalEquipment from 'screens/medicalEquipment/index'
import CreateMedicalEquipment from 'screens/medicalEquipment/create'
import EditMedicalEquipment from 'screens/medicalEquipment/edit'

// Medical Cases
import MedicalCases from 'screens/medicalCases/index'
import CreateMedicalCase from 'screens/medicalCases/create'
import EditMedicalCase from 'screens/medicalCases/edit'

// Emergencies
import Emergencies from 'screens/emergencies'
import CreateEmergency from 'screens/emergencies/create'
import EditEmergency from 'screens/emergencies/edit'

function MainRouter() {
  return (
    <Wrapper>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/organizations" element={<Organizations />} />
        <Route path="/users" element={<Users />} />

        <Route path="/medical-equipment" element={<MedicalEquipment />} />
        <Route path="/medical-equipment/create" element={<CreateMedicalEquipment />} />
        <Route path="/medical-equipment/edit/:id" element={<EditMedicalEquipment />} />

        <Route path="/scholarships" element={<StudentScholarships />} />
        <Route path="/scholarships/create" element={<CreateStudentScholarship />} />
        <Route path="/scholarships/edit/:id" element={<EditStudentScholarship />} />

        <Route path="/families" element={<Families />} />
        <Route path="/families/create" element={<CreateFamily />} />
        <Route path="/families/edit/:id" element={<EditFamily />} />

        <Route path="/emergencies" element={<Emergencies />} />
        <Route path="/emergencies/create" element={<CreateEmergency />} />
        <Route path="/emergencies/edit/:id" element={<EditEmergency />} />

        <Route path="/medical-cases" element={<MedicalCases />} />
        <Route path="/medical-cases/create" element={<CreateMedicalCase />} />
        <Route path="/medical-cases/edit/:id" element={<EditMedicalCase />} />
      </Routes>
    </Wrapper>
  )
}

export default MainRouter
