import axios from '../lib/axios'

export const getOrganizations = async (params) => {
  const res = await axios.get('/organizations', {
    params
  })
  return res
}

export const downloadExcel = async (params) => {
  const res = await axios.get('/organizations/downloadExcel', {
    params
  })
  return res
}

export const addOrganization = async (payload) => {
  const res = await axios.post('/organizations', payload)
  return res
}

export const updateOrganization = async (payload, id) => {
  const res = await axios.patch(`/organizations/${id}`, payload)
  return res
}

export const deleteOrganization = async ({ id }) => {
  const res = await axios.delete(`/organizations/${id}`)
  return res
}
