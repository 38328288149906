import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis, ResponsiveContainer
} from 'recharts'

export default function CustomBarChart({ data, title, dataKey = 'count' }) {
  const { t } = useTranslation();

  return (
    <div className="card">
      <div className="card-header ui-sortable-handle">
        <h3 className="card-title">
          <i className="fas fa-eye mr-2" />
          {t(title)}
        </h3>
      </div>
      <div className="card-body">
        <ResponsiveContainer width="100%" height={500}>
          <BarChart
            data={data}
            barGap={30}
            barCategoryGap="40%"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            {/* <Legend /> */}
            <Bar dataKey={dataKey} fill="#0088FE" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
