/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';

const rolesModules = {
  admin: [
    'dashboard',
    'organization',
    'user',
    'medicalEquipment',
    'scholarship',
    'family',
    'medicalCase',
    'emergency'
  ],
  organizer: [
    'user',
    'medicalEquipment',
    'scholarship',
    'family',
    'medicalCase',
    'emergency'
  ],
  worker: [
    'medicalEquipment',
    'scholarship',
    'family',
    'medicalCase',
    'emergency'
  ]
}

export default function Sidebar() {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem('user'))
  const { pathname } = useLocation()
  const [refreshForcer, setRefreshForcer] = useState(true)
  const role = useSelector((state) => state?.role?.role)

  const roleHasAccess = (moduleName) => {
    if (role === null) {
      localStorage.removeItem('user')
      window.location.reload()
    }
    const availableModules = rolesModules[role]
    return availableModules.includes(moduleName)
  }

  useEffect(() => {
    setRefreshForcer(false)
  }, [pathname])

  useEffect(() => {
    if (!refreshForcer)
      setRefreshForcer(true)
  }, [refreshForcer])
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4 min-vh-100 position-fixed ">
      <a href="#" className="brand-link">
        <img src="/dist/img/logo.png" alt="Iswa Logo" className="brand-image img-circle elevation-3" />
        <span className="brand-text font-weight-light">&nbsp;</span>
      </a>
      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          {/* <div className="image">
            <img src="/dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User" />
          </div> */}
          <div className="info">
            <a href="/" className="d-block">{user.name}</a>
          </div>
        </div>
        {/* <div className="form-inline">
          <div className="input-group" data-widget="sidebar-search">
            <input className="form-control form-control-sidebar" type="search" placeholder={t('Search')} aria-label="Search" />
            <div className="input-group-append">
              <button className="btn btn-sidebar">
                <i className="fas fa-search fa-fw"></i>
              </button>
            </div>
          </div>
        </div> */}
        {refreshForcer ? (
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
              {roleHasAccess('dashboard') && (
                <li className="nav-item">
                  <NavLink to="/" className={({ isActive }) => `nav-link ${isActive ? " active" : ""}`}>
                    <i className="nav-icon fas fa-tachometer-alt" />
                    <p>
                      {t('Dashboard')}
                    </p>
                  </NavLink>
                </li>
              )}
              {roleHasAccess('organization') && (
                <li className='nav-item'>
                  <NavLink to="/organizations" className={({ isActive }) => `nav-link ${isActive ? " active" : ""}`}>
                    <i className="nav-icon fas fa-sitemap" />
                    <p>
                      {t('Organizations')}
                    </p>
                  </NavLink>
                </li>
              )}
              {roleHasAccess('user') && (
                <li className='nav-item'>
                  <NavLink to="/users" className={({ isActive }) => `nav-link ${isActive ? " active" : ""}`}>
                    <i className="nav-icon fas fa-users" />
                    <p>
                      {t('Users')}
                    </p>
                  </NavLink>
                </li>
              )}
              {roleHasAccess('medicalCase') && (
                <li className='nav-item'>
                  <NavLink to="/medical-cases" className={({ isActive }) => `nav-link ${isActive ? " active" : ""}`}>
                    <i className="nav-icon fas fa-users" />
                    <p>
                      {t('Medical Cases')}
                    </p>
                  </NavLink>
                </li>
              )}
              {roleHasAccess('scholarship') && (
                <li className='nav-item'>
                  <NavLink to="/scholarships" className={({ isActive }) => `nav-link ${isActive ? " active" : ""}`}>
                    <i className="nav-icon fa-solid fas fa-graduation-cap"></i>
                    <p>
                      {t('Student Scholarships')}
                    </p>
                  </NavLink>
                </li>
              )}
              {roleHasAccess('family') && (
                <li className='nav-item'>
                  <NavLink to="/families" className={({ isActive }) => `nav-link ${isActive ? " active" : ""}`}>
                    <i className="nav-icon fas fa-user-friends"></i>
                    <p>
                      {t('Families')}
                    </p>
                  </NavLink>
                </li>
              )}
              {roleHasAccess('medicalEquipment') && (
                <li className='nav-item'>
                  <NavLink to="/medical-equipment" className={({ isActive }) => `nav-link ${isActive ? " active" : ""}`}>
                    <i className="nav-icon fa-solid fas fa-hospital-user"></i>
                    <p>
                      {t('Medical Equipment')}
                    </p>
                  </NavLink>
                </li>
              )}
              {roleHasAccess('emergency') && (
                <li className='nav-item'>
                  <NavLink to="/emergencies" className={({ isActive }) => `nav-link ${isActive ? " active" : ""}`}>
                    <i className="nav-icon fa-solid fas fa-exclamation-triangle"></i>
                    <p>
                      {t('Emergency')}
                    </p>
                  </NavLink>
                </li>
              )}
            </ul>
          </nav>
        ) : null
        }
      </div>
    </aside>
  )
}