import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { showLoader, hideLoader } from 'redux/slices/loader'
import { getScholarship, updateScholarship } from 'api/scholarships'
import compressImage from 'lib/imageCompress'
import imagePlaceholder from 'assets/image-placeholder.png'
import { Toast } from 'components'
import { useTranslation } from 'react-i18next'

export default function EditStudentScholarship() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const [toastOpen, setToastOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [idPicture, setIdPicture] = useState(null)
  const [birthCertificatePicture, setBirthCertificatePicture] = useState(null)
  const [images, setImages] = useState({
    id_picture: null,
    birth_certificate_picture: null
  })
  const [scholarshipFormData, setScholarshipFormData] = useState({
    full_name: null,
    birth_date: null,
    marital_status: null,
    residency_location: null,
    nationality: null,
    phone_number: null,
    land_line: null,
    medical_status: null,
    disability: null,
    school_level: null,
    gpa: null,
    school_name: null,
    school_location: null
  })
  const maritalStatuses = ['Single', 'Married', 'Divorced', 'Widowed']
  const scholarshipFormValidation = useFormik({
    enableReinitialize: true,
    initialValues: scholarshipFormData,
    validationSchema: yup.object().shape({
      full_name: yup.string(t('Must provide Full Name')).trim().required(),
    }),
    onSubmit: (values) => {
      dispatch(showLoader())
      const data = { ...values, ...images }
      updateScholarship(data, id)
        .then((response) => {
          dispatch(hideLoader())
          if (response.status === 200) {
            navigate('/scholarships')
          }
        }).catch(response => {
          setErrorMessage('Internal Server Error!')
          dispatch(hideLoader())
        })
    }
  })

  useEffect(() => {
    dispatch(showLoader())
    if (isNaN(id)) {
      navigate('/scholarships')
    } else {
      getScholarship(id)
        .then((response) => {
          const form = response.data.data
          if (form.id_picture) {
            setIdPicture(`/${form.id_picture}`)
          }
          if (form.birth_certificate_picture) {
            setBirthCertificatePicture(`/${form.birth_certificate_picture}`)
          }
          setScholarshipFormData(form)
          dispatch(hideLoader())
        })
        .catch((response) => {
          setErrorMessage(t('Internal Server Error!'))
          dispatch(hideLoader())
        })
    }
  }, [])

  useEffect(() => {
    if (errorMessage !== '') {
      setToastOpen(true)
    }
  }, [errorMessage])

  useEffect(() => {
    if (toastOpen === false) {
      setErrorMessage('')
    }
  }, [toastOpen])

  return (
    <>
      <Toast open={toastOpen} onClose={() => setToastOpen(false)} message={errorMessage} type="error" />
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{t('Edit Scholarships')}</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <form
                  id="form"
                  encType="multipart/form-data"
                  onSubmit={scholarshipFormValidation.handleSubmit}
                >
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label htmlFor="full_name">
                        {t('Full Name')}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        id="full_name"
                        name="full_name"
                        type="text"
                        onChange={scholarshipFormValidation.handleChange}
                        value={scholarshipFormValidation.values?.full_name}
                        className={`form-control ${scholarshipFormValidation.touched.full_name && scholarshipFormValidation.errors.full_name && 'is-invalid'}`}
                      />
                      {scholarshipFormValidation.touched.full_name && scholarshipFormValidation.errors.full_name && (<span className="error invalid-feedback">{scholarshipFormValidation.errors.full_name}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="birthdate">{t('Date of birth')}</label>
                      <input
                        type="date"
                        id="birthdate"
                        name="birth_date"
                        autoComplete="off"
                        data-date="23 May 2022"
                        data-date-format="DD MMMM YYYY"
                        onChange={scholarshipFormValidation.handleChange}
                        value={scholarshipFormValidation.values?.birth_date}
                        className={`form-control ${scholarshipFormValidation.touched.birth_date && scholarshipFormValidation.errors.birth_date && 'is-invalid'}`}
                      />
                      {scholarshipFormValidation.touched.birth_date && scholarshipFormValidation.errors.birth_date && (<span className="error invalid-feedback">{scholarshipFormValidation.errors.birth_date}</span>)}
                      <span className="focus-border" />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="marital_status">
                        {t('Marital Status')}
                      </label>
                      <select
                        value={scholarshipFormValidation.values?.marital_status}
                        name="marital_status"
                        onChange={scholarshipFormValidation.handleChange}
                        className={`custom-select ${scholarshipFormValidation.touched.marital_status && scholarshipFormValidation.errors.marital_status && 'is-invalid'}`}
                      >
                        <option value="" selected disabled>{t('Choose Marital Status')}</option>
                        {maritalStatuses.map(e => (<option key={e} value={t(e, { lng: 'ar' })}>{t(e)}</option>))}
                      </select>
                      {scholarshipFormValidation.touched.marital_status && scholarshipFormValidation.errors.marital_status && (<span className="error invalid-feedback">{scholarshipFormValidation.errors.marital_status}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="residency_location">{t('Location of residence')}</label>
                      <input
                        id="residency_location"
                        name="residency_location"
                        type="text"
                        onChange={scholarshipFormValidation.handleChange}
                        value={scholarshipFormValidation.values?.residency_location}
                        className={`form-control ${scholarshipFormValidation.touched.residency_location && scholarshipFormValidation.errors.residency_location && 'is-invalid'}`}
                      />
                      {scholarshipFormValidation.touched.residency_location && scholarshipFormValidation.errors.residency_location && (<span className="error invalid-feedback">{scholarshipFormValidation.errors.residency_location}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="nationality">
                        {t('Nationality')}
                      </label>
                      <input
                        id="nationality"
                        type="text"
                        name="nationality"
                        onChange={scholarshipFormValidation.handleChange}
                        value={scholarshipFormValidation.values?.nationality}
                        className={`form-control ${scholarshipFormValidation.touched.nationality && scholarshipFormValidation.errors.nationality && 'is-invalid'}`}
                      />
                      {scholarshipFormValidation.touched.nationality && scholarshipFormValidation.errors.nationality && (<span className="error invalid-feedback">{scholarshipFormValidation.errors.nationality}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="phone_number">
                        {t('Phone')}
                      </label>
                      <input
                        id="phone_number"
                        type="number"
                        name="phone_number"
                        onChange={scholarshipFormValidation.handleChange}
                        value={scholarshipFormValidation.values?.phone_number}
                        className={`form-control ${scholarshipFormValidation.touched.phone_number && scholarshipFormValidation.errors.phone_number && 'is-invalid'}`}
                      />
                      {scholarshipFormValidation.touched.phone_number && scholarshipFormValidation.errors.phone_number && (<span className="error invalid-feedback">{scholarshipFormValidation.errors.phone_number}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="land_line">
                        {t('Land Line')}
                      </label>
                      <input
                        id="land_line"
                        type="number"
                        name="land_line"
                        onChange={scholarshipFormValidation.handleChange}
                        value={scholarshipFormValidation.values?.land_line}
                        className={`form-control ${scholarshipFormValidation.touched.land_line && scholarshipFormValidation.errors.land_line && 'is-invalid'}`}
                      />
                      {scholarshipFormValidation.touched.land_line && scholarshipFormValidation.errors.land_line && (<span className="error invalid-feedback">{scholarshipFormValidation.errors.land_line}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="medical_status">
                        {t('Medical Status')}
                      </label>
                      <input
                        id="medical_status"
                        type="text"
                        name="medical_status"
                        onChange={scholarshipFormValidation.handleChange}
                        value={scholarshipFormValidation.values?.medical_status}
                        className={`form-control ${scholarshipFormValidation.touched.medical_status && scholarshipFormValidation.errors.medical_status && 'is-invalid'}`}
                      />
                      {scholarshipFormValidation.touched.medical_status && scholarshipFormValidation.errors.medical_status && (<span className="error invalid-feedback">{scholarshipFormValidation.errors.medical_status}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="disability">{t('Disability')}</label>
                      <input
                        id="disability"
                        type="text"
                        name="disability"
                        onChange={scholarshipFormValidation.handleChange}
                        value={scholarshipFormValidation.values?.disability}
                        className={`form-control ${scholarshipFormValidation.touched.disability && scholarshipFormValidation.errors.disability && 'is-invalid'}`}
                      />
                      {scholarshipFormValidation.touched.disability && scholarshipFormValidation.errors.disability && (<span className="error invalid-feedback">{scholarshipFormValidation.errors.disability}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="levelOfSchool">{t('Current Level of school')}</label>
                      <input
                        type="text"
                        name="school_level"
                        id="school_level"
                        onChange={scholarshipFormValidation.handleChange}
                        value={scholarshipFormValidation.values?.school_level}
                        className={`form-control ${scholarshipFormValidation.touched.school_level && scholarshipFormValidation.errors.school_level && 'is-invalid'}`}
                      />
                      {scholarshipFormValidation.touched.school_level && scholarshipFormValidation.errors.school_level && (<span className="error invalid-feedback">{scholarshipFormValidation.errors.school_level}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="gpa">{t('gpa')}</label>
                      <input
                        type="number"
                        name="gpa"
                        id="gpa"
                        onChange={scholarshipFormValidation.handleChange}
                        value={scholarshipFormValidation.values?.gpa}
                        className={`form-control ${scholarshipFormValidation.touched.gpa && scholarshipFormValidation.errors.gpa && 'is-invalid'}`}
                      />
                      {scholarshipFormValidation.touched.gpa && scholarshipFormValidation.errors.gpa && (<span className="error invalid-feedback">{scholarshipFormValidation.errors.gpa}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="school_name">{t('School Name')}</label>
                      <input
                        type="text"
                        name="school_name"
                        id="school_name"
                        onChange={scholarshipFormValidation.handleChange}
                        value={scholarshipFormValidation.values?.school_name}
                        className={`form-control ${scholarshipFormValidation.touched.school_name && scholarshipFormValidation.errors.school_name && 'is-invalid'}`}
                      />
                      {scholarshipFormValidation.touched.school_name && scholarshipFormValidation.errors.school_name && (<span className="error invalid-feedback">{scholarshipFormValidation.errors.school_name}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="school_location">{t('School Location')}</label>
                      <input
                        type="text"
                        name="school_location"
                        id="school_location"
                        onChange={scholarshipFormValidation.handleChange}
                        value={scholarshipFormValidation.values?.school_location}
                        className={`form-control ${scholarshipFormValidation.touched.school_location && scholarshipFormValidation.errors.school_location && 'is-invalid'}`}
                      />
                      {scholarshipFormValidation.touched.school_location && scholarshipFormValidation.errors.school_location && (<span className="error invalid-feedback">{scholarshipFormValidation.errors.school_location}</span>)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="id_picture">{t('Picture Of ID')}</label>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="id_picture"
                          name="id_picture"
                          onChange={compressImage((v) => {
                            setIdPicture(v)
                            setImages(old => ({ ...old, id_picture: v }))
                          })}
                        />
                        <span className="file-input-name-preview col-md-3" />
                        <label className="custom-file-label" htmlFor="customFile" />
                      </div>
                    </div>
                    <div className="form-group col-md-3">
                      <img
                        src={idPicture || imagePlaceholder}
                        alt=""
                        className="img-thumbnail bg-light"
                      />
                    </div>
                    <div className="form-group col-md-3" />
                    <div className="form-group col-md-3">
                      <label htmlFor="birth_certificate_picture">{t('Birth Certificate')}</label>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="birth_certificate_picture"
                          name="birth_certificate_picture"
                          onChange={compressImage((v) => {
                            setBirthCertificatePicture(v)
                            setImages(old => ({ ...old, birth_certificate_picture: v }))
                          })}
                        />
                        <span className="file-input-name-preview col-md-3" />
                        <label className="custom-file-label" htmlFor="customFile" />
                      </div>
                    </div>
                    <div className="form-group col-md-3">
                      <img
                        src={birthCertificatePicture || imagePlaceholder}
                        alt="..."
                        className="img-thumbnail bg-light"
                      />
                    </div>
                    <div className="form-group col-md-3" />
                  </div>
                  <div className="row">
                    <div className="col d-flex justify-content-center">
                      <button type="submit" className="btn btn-primary">{t('Save')}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
