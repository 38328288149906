import { createSlice } from '@reduxjs/toolkit'

export const loaderSlice = createSlice({
  name: 'loader',
  initialState: {
    loading: false,
  },
  reducers: {
    showLoader: (state) => {
      state.loading = true
    },
    hideLoader: (state) => {
      state.loading = false
    },
  },
})

// Action creators are generated for each case reducer function
export const { showLoader, hideLoader } = loaderSlice.actions

export default loaderSlice.reducer
