import axios from '../lib/axios'

export const getUsers = async (params) => {
  const res = await axios.get('/users', {
    params
  })
  return res
}

export const addUser = async (payload) => {
  const res = await axios.post('/users', payload)
  return res
}

export const updateUser = async (payload, id) => {
  const res = await axios.patch(`/users/${id}`, payload)
  return res
}

export const deleteUser = async ({ id }) => {
  const res = await axios.delete(`/users/${id}`)
  return res
}

export const downloadExcel = async (params) => {
  const res = await axios.get('/users/downloadExcel', {
    params
  })
  return res
}
