import axios from '../lib/axios'

export const getScholarship = async (id) => {
  const res = await axios.get(`/scholarships/${id}`)
  return res
}

export const getScholarships = async (params) => {
  const res = await axios.get('/scholarships', {
    params
  })
  return res
}

export const addScholarship = async (payload) => {
  const res = await axios.post('/scholarships', payload)
  return res
}

export const updateScholarship = async (payload, id) => {
  const res = await axios.patch(`/scholarships/${id}`, payload)
  return res
}

export const deleteScholarship = async ({ id }) => {
  const res = await axios.delete(`/scholarships/${id}`)
  return res
}

export const downloadExcel = async (params) => {
  const res = await axios.get('/scholarships/downloadExcel', {
    params
  })
  return res
}
